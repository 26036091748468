import { computed } from '@vue/composition-api'

export const useOptionClass = (decorator: string | undefined, defaultDecorator: string = 'default') => {
  return computed<string>(() => {
    if (!decorator || decorator === defaultDecorator) {
      return ''
    }

    return `--${decorator}`
  })
}
