import { LinkProps } from '../../../dsl/atoms/Link'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const TRUSTED_SHOP_KEY = 'InitTrustedShopMixin'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface TrustedShopConfig {
  reviewsPeriod: string
  shouldLoadTrusted: boolean
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const TRUSTED_SHOP_CONFIG_MAP: TrustedShopConfig = {
  reviewsPeriod: '30days',
  shouldLoadTrusted: false
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface AggregateTrustedRatingData {
  img: string
  avgRating: string | number
  maxRating: string | number
  link: Pick<LinkProps, 'label' | 'target'>
}
