// Copyright © 2021 Move Closer

import { AppModule, Module } from '@movecloser/front-core'

import { routesFactory as routes } from './routes'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@AppModule({
  name: 'root',
  routes
})
export class RootModule extends Module {}
