import { Model } from '@movecloser/front-core'
import { ILoyalty, LoyaltyData, LoyaltyPayload } from '../contracts/loyalty'
import { BenefitProgram } from '../contracts/programs'

export class Loyalty extends Model<LoyaltyData> implements ILoyalty {
  protected boot (): void {
    this.initialValues = {}
    this.modelProperties = [
      'kameleon',
      'friendsAndFamily',
      'milesAndMore'
    ]
  }

  public getCurrentPrograms (exclude: BenefitProgram[] = []): BenefitProgram[] {
    const currentPrograms: BenefitProgram[] = []

    Object.values(BenefitProgram).forEach(program => {
      if (this._data[program] && !exclude.includes(program)) {
        currentPrograms.push(program)
      }
    })

    return currentPrograms
  }

  public getPayload (exclude: BenefitProgram[] = []): LoyaltyPayload {
    return Object.entries(this._data)
      .filter(([program]) => !exclude.includes(program as BenefitProgram))
      .reduce((acc, [program, value]) => ({ ...acc, [program]: value }), {} as LoyaltyPayload)
  }
}
