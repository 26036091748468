
import { Component } from 'vue-property-decorator'
import { ResourceActionFailed } from '@movecloser/front-core'

import { ConnectorErrors } from '../exceptions'
import { logger } from '../logger'

import { ToastMixin } from '../../front/shared/mixins/toast.mixin'

/**
 * Component that every `form component` should mixin.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component({ name: 'FormErrorsMixin' })
export class FormErrorsMixin extends ToastMixin {
  /**
   * General error of form (for alerts mostly)
   */
  public error: string | null = null

  /**
   * Object to store form errors (field errors)
   */
  public errors: null | Record<string, string[]> = null

  /**
   * State of form
   */
  public isSubmitted: boolean | null = null

  /**
   * Determine whether errors should be passed through i18n.
   */
  protected translateErrors: boolean = false

  /**
   * Clear all set errors.
   */
  public clearErrors (): void {
    this.errors = null
  }

  /**
   * Resolves the errors for the specified field.
   */
  public getErrors (key: string): string[] | undefined {
    if (!this.errors || !this.errors[key]) {
      return
    }

    // If API return other than translation key, it will still be displayed.
    return this.translateErrors ? this.errors[key].map(e => String(this.$t(e))) : this.errors[key]
  }

  /**
   * Handles @error event of `Form`
   */
  public onErrors (errors: Record<string, string[]>): void {
    this.errors = errors
  }

  /**
   * Sets error for non-fields error.
   */
  protected setError (e: Error): void {
    this.isSubmitted = false
    if (e instanceof ResourceActionFailed) {
      if (e.status !== ConnectorErrors.Validation && e.status !== ConnectorErrors.Unknown) {
        this.error = e.message
      }

      logger(e.message, 'error')
    } else {
      logger(e.message, 'error')
    }
  }
}

export default FormErrorsMixin
