// Copyright © 2022 Move Closer

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface StaticRoute {
  path: string
  route: string
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface StaticRouteDefinition {
  label: string
  path: string
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function buildStaticRoutesDefinitions (
  moduleName: string,
  routes: string[],
  translationPath: string = '_staticRouting'
): StaticRouteDefinition[] {
  if (!Array.isArray(routes)) {
    return []
  }

  return routes.map(r => {
    return {
      label: `office.${moduleName}.${translationPath}.${r.split('.').join('-')}`,
      path: `${moduleName}.${r}`
    }
  })
}
