

















import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import { ModalProps } from './Modal.contracts'
import { modalProps, useModal } from './Modal.hooks'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
export const Modal = defineComponent({
  name: 'Modal',
  props: modalProps,
  emits: ['close'],

  setup (props: ModalProps) {
    return useModal(props, getCurrentInstance())
  }
})

export default Modal
