// Copyright © 2021 Move Closer

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const IdentityMiddlewareType = Symbol.for('IdentityMiddleware')

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const IdentityServiceType = Symbol.for('IIdentityService')

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface IIdentityService {
  generateSignature (): void
  getSignature (): string
  hasSignature (): boolean
}
