// Copyright © 2022 Move Closer

import { AnyObject, Intersected } from '@movecloser/front-core'

import { AnyDescription, Description, Identifier, Related, RelatedType } from './data.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface AggregatesRelated {
  storeRelated (record: RelatedRecord): void
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum CalledRelatedServiceMethod {
  Describe = 'describe',
  Resolve = 'resolve'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type DescriptionsRecord = Partial<Record<RelatedType, AnyObject>>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface DriverConfig {
  continueOrder?: boolean
  preventDuplicates?: boolean
  take?: number
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ExposesRelatedRecord {
  getRecord (): RelatedRecord
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type IRelatedService = AggregatesRelated & ExposesRelatedRecord & ResolvesRelated<unknown>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type PossibleTypeDriver<D extends Description = AnyDescription, R extends Description = D> =
  RelatedTypeDriver<D, R> | Intersected<RelatedTypeDriver<D, R>, ResolvesRelatedTypeAsync>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface RelatedLoader {
  load (query: RelatedQuery): Promise<RelatedRecord>
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const RelatedLoaderType = Symbol.for('RelatedLoader')

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type RelatedQuery = Partial<Record<RelatedType, Identifier[]>>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type RelatedRecord = Partial<Record<RelatedType, RelatedRecordTypeEntries>>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface RelatedRecordTypeEntries {
  [key: string]: AnyObject
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const RelatedServiceType = Symbol.for('IRelatedService')

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface RelatedToLoad<T> {
  config?: DriverConfig
  method: CalledRelatedServiceMethod
  reject: (error: Error) => void
  related: Related
  resolve: (resolved: T) => void
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface RelatedTypeDriver<D extends Description = AnyDescription, R extends Description = D> {
  canDescribe (id: string, record: RelatedRecord): boolean
  dependentTypes (): RelatedType[]
  describe (id: string, record: RelatedRecord): D
  resolve (id: string, record: RelatedRecord, config: DriverConfig, driverResolver?: ResolvesDriver):
    ResolvedTypeReturns<D, R>
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type RelatedTypeDriverConstructor<D extends Description, R extends Description = D> =
  new () => RelatedTypeDriver<D>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type RelatedTypeDriverRegistry = Partial<Record<RelatedType, PossibleTypeDriver>>

/**
 * TODO: Define correct type.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ResolvedReturns<T, L> = T

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ResolvedTypeReturns<D, R> = R extends D ? R : R[]

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ResolvesDriver = (type: RelatedType) => PossibleTypeDriver

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ResolvesRelated<L> {
  describe<T extends Description> (related: Related): Promise<T>
  resolve<T extends Description> (related: Related, config?: DriverConfig): Promise<ResolvedReturns<T, L>>
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ResolvesRelatedTypeAsync<M extends AnyObject = AnyObject, D extends AnyObject = AnyObject> {
  loadDescription (id: string): Promise<D>
  loadRelated (id: string): Promise<M>
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface SyncResolvesRelated<L> {
  describe<T extends Description> (related: Related): T
  resolve<T extends Description> (related: Related, config?: DriverConfig): ResolvedReturns<T, L>
}
