// Copyright © 2021 Move Closer

import { ImageData, IRelatedService } from '../../contexts'
import { logger } from '../../support'

import { resolveImage } from '../resolvers'

import {
  ContainerBackground,
  ContainerContent,
  ContainerContentInput
} from './Container.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export async function resolveContainerContent (input: ContainerContentInput, relatedService: IRelatedService): Promise<ContainerContent> {
  const background: Partial<ContainerBackground> = {
    color: input.background.color,
    imagePosition: input.background.imagePosition,
    variantColor: input.background.variantColor
  }

  let image: ImageData | undefined
  if (input.background.image) {
    try {
      image = await resolveImage(input.background.image, relatedService)
    } catch (e) {
      logger(e, 'warn')
    }
  }

  background.image = image

  return { ...input, background }
}
