// // Copyright © 2022 Move Closer
//
import { MappingConfig, MappingTypes } from '@movecloser/front-core'
//
// import { NavigationItem, NavigationRawItem, OpenStrategy, Target } from '../contracts'
//
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const linkAdapterMap: MappingConfig = {
  url: {
    type: MappingTypes.Function,
    value: item => (
      item.url.charAt(0) !== '/' ? `/${item.url}` : item.url
    )
  }
}
//
// /**
//  * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
//  */
// const mapLinks = (links?: NavigationRawItem[]): NavigationItem[] => {
//   if (!links || links.length === 0) {
//     return []
//   }
//
//   return links.map(
//     (linkItem: NavigationRawItem) => ({
//       additionalClass: linkItem.additionalClass,
//       isExternal: linkItem.target?.type === Target.External,
//       label: linkItem.label,
//       icon: linkItem.icon,
//       secondaryLabel: linkItem.secondaryLabel,
//       target: linkItem.target?.link,
//       newTab: linkItem.strategy === OpenStrategy.Blank,
//       children: mapLinks(linkItem.children)
//     } as NavigationItem)
//   )
// }
//
// /**
//  * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
//  */
// export const navigationAdapterMap: MappingConfig = {
//   name: 'name',
//   slot: 'slot',
//   links: {
//     type: MappingTypes.Function,
//     value: item => mapLinks(item.links)
//   }
// }
