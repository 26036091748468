/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum WishListVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface WishListData {
  /**
   * Identifier.
   */
  id: string

  /**
   * Name of the wishlist
   */
  name: string

  /**
   * Determines the sum of all items in list.
   */
  itemsCount: number

  /**
   * Determines the items of list
   */
  items: WishListItem[]
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface WishListItem {
  /**
   * Identifier.
   */
  id: string

  /**
   * Determines the product of item.
   */
  product: {
    /**
     * Determines the name of the product.
     */
    name: string

    /**
     * Unique identifier of the product of item in the list.
     */
    uid: string

    /**
     * SKU of the product.
     */
    sku: string
  }

  /**
   * Determines the quantity of the item in the list.
   */
  quantity: number
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const WISHLIST_NAME = 'wishlist'
