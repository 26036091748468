
import { ModulesUiRegistryEntry } from '../../../contexts'
import { resolveReviewsTeaserModuleContent } from '../ReviewsTeaser.helpers'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const reviewsTeaserModule: ModulesUiRegistryEntry = {
  component: () => import(
    /* webpackChunkName: "ui/core/reviews-teaser" */ './ReviewsTeaser.ui.vue'
  ),
  resolver: resolveReviewsTeaserModuleContent
}
