// Copyright © 2022 Move Closer

import { ComputedRef } from '@vue/composition-api'

import { ClassRegistry, ObjectKeyLiterals } from '../use-class-registry'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export interface HasShape {
  shape: string
}

/**
 * Registry binding the element's shape with the applicable CSS class.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export type ShapeRegistry<T extends ObjectKeyLiterals> = ClassRegistry<T>

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export enum ShapeMap {
  Rectangle = 'rectangle',
  Square = 'square'
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export interface UseShapeClassProvides {
  shapeClass: ComputedRef<string>
}
