
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum ClientRectPosition {
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum ScrollDirection {
  Down = 'down',
  Up = 'up'
}
