// Copyright © 2022 Move Closer

import { ImageData, IRelatedService, ModuleContentResolver } from '../../contexts'

import { logger } from '../../support'

import { ResolvedLink } from '../partials'
import { resolveImage, resolveLink } from '../resolvers'

import { AdvertiseBoxItem, AdvertiseBoxModuleContent, AdvertiseBoxModuleContentInput } from './AdvertiseBox.contract'

/**
 * @author Magda Pietruszewska <magda.pietruszewska@movecloser.pl>
 */
export const resolveAdvertiseBoxModuleContent: ModuleContentResolver<AdvertiseBoxModuleContentInput, AdvertiseBoxModuleContent> =
  async (
    input: AdvertiseBoxModuleContentInput,
    relatedService: IRelatedService
  ): Promise<AdvertiseBoxModuleContent> => {
    let items: AdvertiseBoxItem[] = []

    try {
      items = await Promise.all(input.items.map(async ({
        image,
        links
      }) => {
        let resolvedLinks: ResolvedLink[] | null = null
        let resolvedImage: ImageData | null = null

        if (image) {
          resolvedImage = await resolveImage(image, relatedService)
        }

        if (links) {
          resolvedLinks = await Promise.all(links.map(async (link) => {
            let resolvedLink: ResolvedLink
            if (link.relatedTarget) {
              resolvedLink = await resolveLink(link, relatedService)
            } else {
              resolvedLink = link as ResolvedLink
            }
            return resolvedLink
          }))
        }

        return {
          image: resolvedImage,
          links: resolvedLinks
        }
      }))
    } catch (e) {
      logger(e, 'warn')
    }

    return {
      ...input,
      items
    }
  }
