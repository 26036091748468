
import { Component } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { Inject } from '../../../support'
import { ISiteService, SiteServiceType } from '../../../contexts'
import { StructureConfigurable } from '../../../support/mixins'

import { TRUSTED_SHOP_CONFIG_MAP, TRUSTED_SHOP_KEY, TrustedShopConfig } from '../contracts/trustedShop'

/**
 * @author Filip Rurak <filip.rurak@moveclose.pl>
 */
@Component<InitTrustedShopMixin>({
  name: 'InitTrustedShopMixin',
  created () {
    this.config = this.getComponentConfig(TRUSTED_SHOP_KEY, { ...TRUSTED_SHOP_CONFIG_MAP })

    /**
     * @inheritDoc
     */
    this.loadAggregateData()
  }
})
export class InitTrustedShopMixin extends StructureConfigurable {
  @Inject(SiteServiceType, false)
  protected readonly siteService?: ISiteService

  protected config!: TrustedShopConfig

  public get reviewsPeriod (): string {
    return this.getConfigProperty<string>('reviewsPeriod')
  }

  public get shouldLoadTrusted (): boolean {
    return this.getConfigProperty<boolean>('shouldLoadTrusted')
  }

  /**
   * Loads badge TrustedShop aggregate rating data
   * @protected
   */
  protected loadAggregateData () {
    if (!this.siteService || !this.shouldLoadTrusted) {
      return
    }

    const properties = this.siteService?.getActiveSite().properties

    if (properties && properties.eTrusted) {
      const eTrusted = properties.eTrusted as unknown as AnyObject
      const aggregateRating = {
        img: eTrusted.img,
        avgRating: InitTrustedShopMixin.translateRatingFormat(eTrusted.data[this.reviewsPeriod].rating),
        maxRating: InitTrustedShopMixin.translateRatingFormat(Object.keys(eTrusted.data[this.reviewsPeriod].distribution).length),
        link: {
          label: eTrusted.link.label,
          target: eTrusted.link.target
        }
      }

      this.$store.commit('shared/setTrustedShopAggregateRating', aggregateRating)
    }
  }

  private static translateRatingFormat (value: number): string {
    return value.toFixed(2).toString()
  }
}
