










import { Component, Mixins, Prop, Inject as VueInject } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { DisplaySearchResult } from '../../../../contexts'

import { ProductCartMixin } from '../../../checkout/shared/mixins/product-cart.mixin'

import { SearchResultProductDesktop } from './partials/SearchResultProductDesktop.vue'
import { SearchResultProductMobile } from './partials/SearchResultProductMobile.vue'
import {
  SEARCH_RESULT_PRODUCT_ITEM_CONFIG_MAP,
  SEARCH_RESULT_PRODUCT_ITEM_KEY,
  SearchResultProductItemConfig
} from './SearchResultProductItem.config'
import { StructureConfigurable } from '../../../../support/mixins'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../support'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<SearchResultProductItem>({
  name: 'SearchResultProductItem',
  components: {
    SearchResultProductDesktop,
    SearchResultProductMobile
  },
  created () {
    this.config = this.getComponentConfig(SEARCH_RESULT_PRODUCT_ITEM_KEY, { ...SEARCH_RESULT_PRODUCT_ITEM_CONFIG_MAP })
  }
})
export class SearchResultProductItem extends Mixins(ProductCartMixin, StructureConfigurable) implements DisplaySearchResult {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  private readonly isMobile!: () => boolean

  @Prop({ type: Object, required: false })
  public element!: AnyObject

  protected config!: SearchResultProductItemConfig

  public get useEnhancedTranslator (): boolean {
    return this.getConfigProperty('useEnhancedTranslator')
  }
}

export default SearchResultProductItem
