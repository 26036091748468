
import { Component, Vue } from 'vue-property-decorator'

import { Inject, logger } from '../../../../support'

import { CartMutationTypes } from '../../../checkout/contracts'
import { RouteNames as ProfileRoutes } from '../../../profile/routes'
import { RouteName as CheckoutRoutes } from '../../../checkout/routes'

import { AuthControlServiceType, IAuthControl, SocialAuthType, SSOValidCallbackResponse } from '../../contracts'
import { Authentication, AuthServiceType, EventbusType, IEventbus } from '@movecloser/front-core'
import {
  authSSOCustomerData,
  authSSOParamsKey, authSSOSelectedProvider,
  authSSOSessionId,
  AuthSSOSource,
  authSSOSource
} from '../../config/auth'
import { RouteNames as AuthRoutes } from '../../routes'

import { UserModel } from '../contracts'

/**
 * @author Filip Rurak <Filip.rurak@movecloser.pl>
 */
@Component<SSOAuthMixin>({
  name: 'SSOAuthMixin'
})
export class SSOAuthMixin extends Vue {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  public socialPending: boolean = false
  public socialAuthType = SocialAuthType

  /**
   * Handles OAuth SSO request. Response can be:
   * - (422) invalid - prompting user to accept required consents,
   * - (200) valid - will return token
   */
  protected async setSSOCallback (provider: string | null): Promise<void> {
    const SSOParams = localStorage.getItem(authSSOParamsKey)
    const source = localStorage.getItem(authSSOSource)

    if (!SSOParams || !provider) {
      SSOAuthMixin.RemoveSSOStorageKeys()
      await this.$router.push('/')
      return
    }

    try {
      const response = await this.authControl.setSocialCallback(JSON.parse(SSOParams), provider)
      console.log('response from component', response)

      if (response && response.status === 500) {
        SSOAuthMixin.RemoveSSOStorageKeys()
        await this.$router.push({
          name: `profile.${AuthRoutes.Auth}`
        })
        return
      }

      if (response && response.message && response.status === 422) {
        SSOAuthMixin.StoreSSOData(response)
        await this.setRedirect(source)
      }

      if (response && response.status === 500) {
        await this.handleSSOAuthentication(false)
      }

      if (response && response.token && response.token.accessToken) {
        await this.handleSSOAuthentication(true, response, source)
        SSOAuthMixin.RemoveSSOStorageKeys()
      }
    } catch (e) {
      logger(e, 'warn')
      await this.handleSSOAuthentication(false)
    }
  }

  /**
   * Handles valid SSO authentication response
   */
  private async handleSSOAuthentication (valid: boolean, data?: SSOValidCallbackResponse, source?: string | null): Promise<void> {
    switch (valid) {
      case true:
        if (data) {
          this.authService.setToken(data.token!.toAuthToken())

          if (source && source === AuthSSOSource.Cart) {
            this.$store.commit(CartMutationTypes.SetSSOAuthenticated, true)
            this.$store.commit(CartMutationTypes.SetSSOConsentsRequest, false)

            await this.$router.push({
              name: `checkout.${CheckoutRoutes.Checkout}`,
              params: { step: '1' }
            })
            return
          }

          await this.$router.push({
            name: `profile.${ProfileRoutes.Profile}`,
            params: { triggerEvent: 'login' }
          })
        }
        break
      case false:
        this.$store.commit(CartMutationTypes.SetSSOAuthenticated, false)
        SSOAuthMixin.RemoveSSOStorageKeys()
        await this.$router.push('/')
        break
    }
  }

  /**
   * Handles user redirection when necessary
   */
  private async setRedirect (source: string | null): Promise<void> {
    let redirectUri: string = ''
    let redirectQuery: Record<string, string> | null = null

    switch (source) {
      case AuthSSOSource.Auth:
        redirectUri = `auth.${AuthRoutes.Consents}`
        break
      case AuthSSOSource.Cart:
        redirectUri = `checkout.${CheckoutRoutes.Checkout}`
        redirectQuery = { step: '1' }
        break
    }

    if (redirectUri) {
      switch (typeof redirectQuery) {
        case null:
          await this.$router.push({ name: redirectUri })
          break
        case 'object':
          this.$store.commit(CartMutationTypes.SetSSOConsentsRequest, true)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await this.$router.push({ name: redirectUri, query: redirectUri })
          break
      }
    }
  }

  /**
   * Handles saving SSO authentication data
   */
  private static StoreSSOData (data: SSOValidCallbackResponse): void {
    localStorage.setItem(authSSOCustomerData, JSON.stringify({
      email: data.email ?? null,
      name: data.name ?? null
    }))

    localStorage.setItem(authSSOSessionId, JSON.stringify({
      id: data.id ?? null
    }))
  }

  /**
   * Handles removal of all storage items that were used while authentication
   */
  private static RemoveSSOStorageKeys (): void {
    localStorage.removeItem(authSSOParamsKey)
    localStorage.removeItem(authSSOSelectedProvider)
    localStorage.removeItem(authSSOCustomerData)
    localStorage.removeItem(authSSOSessionId)
    localStorage.removeItem(authSSOSource)
  }
}
