import { Injectable } from '@movecloser/front-core'

import { IProfileTabsService, ProfileTab, ProfileTabItem } from './contracts'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Injectable()
export class Service implements IProfileTabsService {
  protected _profileTabs: ProfileTabItem[]

  constructor (profileTabs: ProfileTabItem[]) {
    this._profileTabs = profileTabs
  }

  addTab (profileTab: ProfileTabItem[]): void {
    this._profileTabs.push(...profileTab)
  }

  getTabs (): ProfileTab[] {
    return this._profileTabs.sort((a, b) => a.priority < b.priority ? 1 : -1)
  }
}
