import { Injectable, IResponse, mapModel, ResourceActionFailed } from '@movecloser/front-core'
import { ILoyaltyService } from '../contracts/services'
import { LoyaltyModel, LoyaltyPayload } from '../contracts/loyalty'
import { resolveFromStatus } from '../../../support'
import { IGraphQL } from '../../../contexts'
import { loyaltyAdapterMap } from '../models/loyalty.adapter'
import { Loyalty } from '../models/loyalty'

@Injectable()
export class LoyaltyService implements ILoyaltyService {
  constructor (
    protected readonly graphQlConnector: IGraphQL
  ) {
  }

  public async fetch (): Promise<LoyaltyModel> {
    const response: IResponse = await this.graphQlConnector.call('fetchLoyalty', {})

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return Loyalty.hydrate<LoyaltyModel>(mapModel<LoyaltyModel>(response.data.customer, loyaltyAdapterMap, false))
  }

  public async update (payload: LoyaltyPayload): Promise<void> {
    const { milesAndMore, ...rest } = payload
    const mappedPayload = {
      ...rest,
      miles_and_more: milesAndMore
    }
    const response: IResponse = await this.graphQlConnector.call('updateLoyalty', { payload: mappedPayload })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }
  }
}
