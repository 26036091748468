import { VueConstructor } from 'vue'
import { Component, Mixins } from 'vue-property-decorator'

import { StructureConfigurable } from '../../../support/mixins'

/**
 * TODO: DEPRECATED
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'UseComponentVersion' })
export class UseComponentVersion extends Mixins<StructureConfigurable>(StructureConfigurable) {
  protected readonly variantsComponent: Record<string, VueConstructor> = {}

  /**
   * Determines the component's variant that is chosen.
   */
  public get variant (): VueConstructor {
    const variant = this.getConfigProperty<string>('variant')
    return this.variantsComponent[variant]
  }
}
export default UseComponentVersion
