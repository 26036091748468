import { DocumentNode } from 'graphql'
import { IConfiguration } from '@movecloser/front-core'

import { MagentoVersion } from './version.contracts'

export function getQueryByConfig (config: IConfiguration, queryMap: Record<MagentoVersion, DocumentNode>): DocumentNode {
  const version = config.byKey<MagentoVersion>('magentoVersion', false) ?? MagentoVersion.Default

  return queryMap[version]
}
