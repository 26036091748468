// Copyright © 2022 Move Closer

import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import { CaptureConsole } from '@sentry/integrations'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const initSentry = (): void => {
  if (
    typeof process.env.VUE_APP_SENTRY_DSN_FRONT !== 'string' ||
    process.env.VUE_APP_SENTRY_DSN_FRONT.length === 0
  ) {
    return
  }

  Sentry.init({
    attachProps: true,
    dsn: process.env.VUE_APP_SENTRY_DSN_FRONT,
    environment: process.env.VUE_APP_ENV,
    integrations: [
      new CaptureConsole({ levels: ['error', 'warn'] })
    ],
    logErrors: true,
    release: process.env.VUE_APP_VERSION,
    Vue
  })
}
