// Copyright © 2022 Move Closer

import Vue from 'vue'

import { Context } from '@contract/context'
import { UVuePlugin } from '@contract/uvue-plugin'
import { validateContext } from '@support/validate-context'

// import { MobileESP } from './user-agent.contracts'
// import { mobileESPFactory } from './user-agent.helpers'

const detectMobile = (userAgent: string): boolean => {
  let isMobile = false
  const mobileStrings = [
    'Android',
    'Opera Mini',
    'iPhone',
    'iPad',
    'Windows Phone'
  ]

  for (const s of mobileStrings) {
    isMobile = isMobile || userAgent.indexOf(s) >= 0
  }

  return isMobile
}

/**
 * Plugin responsible for intercepting the API call, extracting the user agent info and populating the Vuex store.
 * Thanks to this plugin you can use checks like `isMobile` or `isDesktop` in the `<script>` or `<template>` section of
 * your Vue SFC.
 *
 * @see State.device
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */

const UserAgentPlugin: UVuePlugin = {
  async created (context: Context) {
    validateContext(context)
    const store = (context.app as Vue).$store

    if (context.isClient) {
      const phoneQuery = matchMedia('(max-width: 768px)')
      const tabletQuery = matchMedia('(min-width: 769px) and (max-width: 992px)')
      const desktopQuery = matchMedia('(min-width: 993px)')

      setTimeout(async () => {
        await store.dispatch('setDevice', {
          isPhone: phoneQuery.matches,
          isTablet: tabletQuery.matches,
          isDesktop: desktopQuery.matches
        })
      }, 0)

      phoneQuery.addEventListener('change', async (e) => {
        await store.dispatch('setDevice', { isPhone: e.matches })
      })
      tabletQuery.addEventListener('change', async (e) => {
        await store.dispatch('setDevice', { isTablet: e.matches })
      })
      desktopQuery.addEventListener('change', async (e) => {
        await store.dispatch('setDevice', { isDesktop: e.matches })
      })
      return
    }

    const req = context.ssr?.req
    if (typeof req === 'undefined') {
      throw new Error('UserAgentPlugin.created(): FATAL! [context.ssr?.req] is [undefined]!')
    }

    const userAgent: string | undefined = req.headers['user-agent']
    if (typeof userAgent !== 'string') {
      throw new Error('UserAgentPlugin.created(): FATAL! [userAgent] is not a type of [string]!')
    }

    // const mobileESP: MobileESP = mobileESPFactory()
    // mobileESP.InitDeviceScan(userAgent)

    const isPhone: boolean = detectMobile(userAgent)
    const isTablet = false
    const isDesktop: boolean = !isPhone

    // const isMobile: boolean = isPhone || isTablet

    // We can safely use the `as` keyword here, as all the required checks have been made inside the above
    // `validateContext()` function.
    await store.dispatch(
      'setDevice',
      { isDesktop, isPhone, isTablet, userAgent }
    )
  }
}

export default UserAgentPlugin
