










import { Component, Vue } from 'vue-property-decorator'
import { ModalPayload, ModalState } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { Inject } from '../../../../support'

import { IDrawer, DrawerType } from '../../contracts/services'

import { drawerConfig } from './DrawerWrapper.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<DrawerWrapper>({
  name: 'DrawerWrapper',
  mounted (): void {
    this.initDrawer()
  }
})
export class DrawerWrapper extends Vue {
  @Inject(DrawerType)
  protected readonly drawerConnector!: IDrawer

  /**
   * Chosen drawer's Vue component.
   */
  public component: VueConstructor | null = null

  /**
   * Chosen modal's config.
   */
  public config = {}

  /**
   * Determines whether the modal should be open.
   */
  public open: boolean = false

  /**
   * Chosen modal's payload (data).
   */
  public payload: ModalPayload | null = null

  /**
   * Handles the `@close` event on the Drawer.
   */
  public onClose (): void {
    this.closeDrawer()
    setTimeout(() => {
      this.clearModalData()
    }, 200)
  }

  /**
   * Clears the inner drawer's data.
   */
  private clearModalData (): void {
    this.component = null
    this.config = {}
    this.open = false
    this.payload = null
  }

  /**
   * Closes the modal.
   */
  private closeDrawer (): void {
    this.drawerConnector.close()
  }

  private initDrawer (): void {
    this.drawerConnector.subscribe((modalState: ModalState) => {
      if (this.drawerConnector.isOpened) {
        this.config = {
          ...drawerConfig,
          ...modalState.config
        }
        this.component = this.drawerConnector.getComponent<VueConstructor>(String(this.drawerConnector.name))
        this.payload = modalState.payload
      }

      this.open = modalState.opened
    })
  }
}

export default DrawerWrapper
