// Copyright © 2021 Move Closer

import { Payload } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class SetupFailed extends Error {
  public name: string = 'SetupFailed'

  constructor (
    public message: string,
    public status: number | string,
    public payload: Payload = {}
  ) {
    super()
  }
}
