
import { Module } from 'vuex'
import { ProductsState, State } from './contracts/store'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const storeFactory = (): Module<ProductsState, State> => ({
  namespaced: true,

  state: () => ({
    averageProductRating: 0,
    productReviewsData: null,
    totalReviewsCount: 0,
    rateSummary: null
  }),

  mutations: {
    setAverageProductRating (state, value) {
      state.averageProductRating = value
    },
    setProductReviewsData (state, value) {
      state.productReviewsData = value
    },
    setTotalReviewsCount (state, value) {
      state.totalReviewsCount = value
    },
    setRateSummary (state, value) {
      state.rateSummary = value
    }
  },

  getters: {
    getAverageProductRating: state => state.averageProductRating,
    getProductReviewsData: state => state.productReviewsData,
    getTotalReviewsCount: state => state.totalReviewsCount,
    getRateSummary: state => state.rateSummary
  }
})
