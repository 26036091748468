// Copyright © 2021 Move Closer

import { RoutesFactory } from '@movecloser/front-core'

import ErrorView from './views/ErrorView.vue'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const routesFactory: RoutesFactory = () => {
  return [
    {
      path: '/error',
      name: 'error',
      component: ErrorView,
      meta: { static: false }
    }
  ]
}
