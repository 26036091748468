import { AnyObject, Injectable, mapModel, ResourceActionFailed } from '@movecloser/front-core'

import { resolveFromStatus } from '../../../../support'
import { GraphQLConnector } from '../../../../contexts'

import { IStoreConfigService } from './contracts'
import { storeConfigMappingConfig } from './map'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Injectable()
export class StoreConfigService implements IStoreConfigService {
  private readonly connector: GraphQLConnector
  private _config: AnyObject = {}

  constructor (connector: GraphQLConnector) {
    this.connector = connector
  }

  /**
   * @inheritDoc
   */
  public async getConfig (): Promise<AnyObject> {
    return new Promise((resolve, reject) => {
      if (Object.keys(this._config).length > 0) {
        resolve(this._config)
      }

      // Probably we will not go till this part, it is mostly for `safety`
      // Information about configuration is fetched in SSR, but still milliseconds are required to them to appear in
      // front.
      this.loadConfig().then(() => {
        resolve(this._config)
      }).catch((e) => reject(e))
    })
  }

  /**
   * @inheritDoc
   */
  public getConfigValueByKey<T extends string | object | number = string> (key: string): T {
    if (!(key in this._config)) {
      throw new Error(`No value found for given key: [${key}]`)
    }

    return this._config[key] as T
  }

  /**
   * @inheritDoc
   */
  public async loadConfig (): Promise<void> {
    const response = await this.connector.call('storeConfig')

    if (response.errors && !response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    this._config = mapModel(response.data.storeConfig, storeConfigMappingConfig, false)
  }
}
