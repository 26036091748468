// Copyright © 2022 Move Closer

import { RoutesFactory } from '@movecloser/front-core'

import ContentView from './views/ContentView.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const routesFactory: RoutesFactory = () => {
  return [
    {
      path: '/:slug(.*)*',
      name: 'display',
      component: ContentView,
      meta: { static: false }
    }
  ]
}
