
import { QueryParams } from '@movecloser/front-core/lib/contracts/filter-parser'

import { SearchGroup, SearchResultItemResolver, SearchResultsConfig } from '../../../../contexts'

import { ITabsService, TabItem } from '../tabs'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface ISearchService extends ITabsService<SearchResultItemTab> {
  getConfig (): SearchResultsConfig
  getInitialData (): SearchGroup
  setConfig (): void
  search (phrase: string, query: QueryParams): Promise<SearchGroup[]>
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface SearchResultItemTab extends TabItem {
  /**
   * Determines the configuration for `SearchResults` component.
   * @see SearchResultsProps.config
   */
  preview: SearchResultsConfig

  resolver: SearchResultItemResolver
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const SEARCH_CONFIG = 'search'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface SearchConfig {
  useSyneriseApi: boolean
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const SearchServiceType = Symbol.for('ISearchService')
