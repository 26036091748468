// Copyright © 2022 Move Closer

import { ComputedRef } from '@vue/composition-api'

import { ClassRegistry, ObjectKeyLiterals } from '../use-class-registry'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export interface HasVariant {
  variant: string
}

/**
 * Registry binding the element's variant with the applicable CSS class.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export type VariantRegistry<T extends ObjectKeyLiterals> = ClassRegistry<T>

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export enum VariantMap {
  Full = 'full',
  Outline = 'outline'
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export interface UseVariantClassProvides {
  variantClass: ComputedRef<string>
}
