// Copyright © 2022 Move Closer

/**
 * Logs the passed-in message to the console.
 *
 * @param message - The content (message) to be logged.
 * @param [level="info"] - Information level.
 * @param [logOnServer=true] - Whether the output should be visible in the Node server's console, too.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const logger = (message: unknown, level: LoggerLevel = 'info', logOnServer?: boolean): void => {
  if (
    process.env.VUE_APP_DEBUG !== 'true' ||
    (typeof window === 'undefined' && !logOnServer)
  ) {
    return
  }

  if (Array.isArray(message)) {
    // eslint-disable-next-line no-console
    console[level](...message)
  } else {
    // eslint-disable-next-line no-console
    console[level](message instanceof Error && level !== 'error' ? message.message : message)
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type LoggerLevel = 'debug' | 'error' | 'info' | 'log' | 'warn'
