// Copyright © 2021 Move Closer

import { Headers, Injectable } from '@movecloser/front-core'

import {
  GraphQLMiddleware,
  GraphQLMiddlewareBeforeResult,
  ISiteService,
  MutationOptions,
  QueryDefinition,
  QueryOptions,
  SiteModel
} from '../../../contexts'
import { logger } from '../../../support'

/**
 * Middleware that decorates the API calls with the information about the currently-active domain.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class SiteGraphQLMiddleware implements GraphQLMiddleware {
  private readonly sites: ISiteService

  constructor (siteService: ISiteService) {
    this.sites = siteService
  }

  public beforeCall (
    definition: QueryDefinition,
    headers: Headers,
    query: MutationOptions | QueryOptions
  ): GraphQLMiddlewareBeforeResult {
    if (typeof definition.meta.omitSite === 'boolean' && definition.meta.omitSite) {
      return { query, headers }
    }

    logger(['[SiteGraphQLMiddleware]:', this.domainHeader], 'debug')

    return {
      query,
      headers: {
        ...headers,
        ...this.domainHeader
      }
    }
  }

  private get domainHeader (): Headers {
    const site: SiteModel = this.sites.getActiveSite()
    const fallbackLocale = process.env.VUE_APP_HTTP_D24_API_LOCALE || 'en-GB'

    return {
      'Accept-Language': site.locale || fallbackLocale,
      Store: site.getProperty('storeCode', '') as string,
      'X-Site': `${site.id}`
    }
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const SiteGraphQLMiddlewareType = Symbol.for('ISiteGraphQLMiddleware')
