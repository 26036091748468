// Copyright © 2021 Move Closer

import { Ref } from '@vue/composition-api'

import { ComponentObjectPropsOptions } from '../../vue-api'

import { ObjectKeyLiterals, userClassRegistry } from '../use-class-registry'

import { HasVariant, UseVariantClassProvides, VariantMap, VariantRegistry } from './has-variant.contracts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const getVariantPropDefinition = (defaultVariant: string = VariantMap.Full): ComponentObjectPropsOptions<HasVariant> => {
  return {
    variant: {
      type: String,
      required: false,
      default: defaultVariant
    }
  }
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const hasVariantProp: ComponentObjectPropsOptions<HasVariant> = getVariantPropDefinition()

/**
 * @param variant
 * @param variantClassRegistry
 * @param componentName
 * @param strict
 * @param fallbackClass
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const useVariantClass = <T extends ObjectKeyLiterals = string> (
  variant: Ref<T>,
  variantClassRegistry: VariantRegistry<T>,
  componentName: string,
  strict: boolean = false,
  fallbackClass: string = ''
): UseVariantClassProvides => {
  return {
    variantClass: userClassRegistry(
      variant,
      variantClassRegistry,
      componentName,
      'useVariant',
      strict,
      fallbackClass
    )
  }
}
