
import { Module } from 'vuex'

import { OrdersState } from './contracts/store'
import { State } from '../shared/contracts/store'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const storeFactory = (): Module<OrdersState, State> => ({
  namespaced: true,

  state: () => ({
    possibleOrderStatuses: {}
  }),

  mutations: {
    setPossibleOrderStatuses (state, payload) {
      state.possibleOrderStatuses = payload
    }
  },

  getters: {
    getPossibleOrderStatuses: state => state.possibleOrderStatuses
  }
})
