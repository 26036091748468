// Copyright © 2021 Move Closer

import Vue from 'vue'
// @ts-expect-error - For the unknown reasons the `.d.ts` file won't work.
import VueProgressBar from 'vue-progressbar'
import { NavigationGuardNext, Route } from 'vue-router'

import { UVuePlugin } from '@contract/uvue-plugin'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const ProgressBarPlugin: UVuePlugin = {
  async beforeCreate ({ isClient }): Promise<void> {
    if (!isClient) {
      return
    }

    Vue.use(VueProgressBar, {
      color: '#99072C',
      failedColor: '#ba1400',
      thickness: '4px'
    })
  },

  async beforeStart ({ app, isClient, router }): Promise<void> {
    if (!isClient || typeof app === 'undefined') {
      return
    }

    // On boot start progress
    app.$Progress.start()

    // On route change start progress
    router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
      app.$Progress.start()
      next()
      app.$Progress.increase(30)
    })

    // On route rendered: stop
    router.afterEach(() => {
      app.$Progress.finish()
    })

    // On route error: fail
    router.onError(() => {
      app.$Progress.fail()
    })

    // On redirect: stop
    app.$on('router.redirect', () => {
      app.$Progress.finish()
    })
  },

  catchError ({ app, isClient }): void {
    if (isClient && typeof app !== 'undefined') {
      app.$Progress.fail()
    }
  },

  // On route error: fail
  async routeError ({ app, isClient }): Promise<void> {
    if (isClient && typeof app !== 'undefined') {
      app.$Progress.fail()
    }
  },

  // When all is ready: stop
  ready ({ app, isClient }): void {
    if (isClient && typeof app !== 'undefined') {
      app.$Progress.finish()
    }
  }
}

export default ProgressBarPlugin
