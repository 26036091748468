






















import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import { CardProps } from './Card.contracts'
import { cardProps, useCard } from './Card.hooks'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const Card = defineComponent({
  name: 'Card',
  emits: ['mouseOver', 'mouseLeave'],
  props: cardProps,
  setup (props: CardProps) {
    return useCard(props, getCurrentInstance())
  }
})
export default Card
