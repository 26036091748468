
import { Injectable } from '@movecloser/front-core'

import { ComponentsStructureConfig } from '../../../../support/mixins'

import { IOrderStatusesService, StatusModuleConfigEntry } from './contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Injectable()
export class Service implements IOrderStatusesService {
  protected config!: ComponentsStructureConfig

  /** Collection of all possible statuses */
  protected _orderStatuses: Array<string> = []
  /** Collection of statuses that should have special properties */
  protected _highlightedOrderStatuses: Array<string> = []
  /** Collection of statuses that trigger retry payment action */
  protected _retryOrderStatuses: Array<string> = []
  /** Collection of statuses that trigger return order action */
  protected _returnOrderStatuses: Array<string> = []

  constructor (orderStatuses: Array<string>) {
    this._orderStatuses = orderStatuses
  }

  /**
   * @inheritDoc
   * @param config
   */
  public addStatuses (
    config: ComponentsStructureConfig
  ) {
    const modules = config.hasOwnProperty('modules') ? config.modules : null

    if (!modules) {
      return
    }

    if (modules.hasOwnProperty('possibleOrderStatuses')) {
      for (const status of modules[StatusModuleConfigEntry.PossibleOrderStatuses]) {
        this._orderStatuses.push(status)
      }
    }

    if (modules.hasOwnProperty('highlightedOrderStatuses')) {
      for (const status of modules[StatusModuleConfigEntry.HighlightedOrderStatuses]) {
        this._highlightedOrderStatuses.push(status)
      }
    }

    if (modules.hasOwnProperty('retryOrderStatuses')) {
      for (const status of modules[StatusModuleConfigEntry.RetryOrderStatuses]) {
        this._retryOrderStatuses.push(status)
      }
    }

    if (modules.hasOwnProperty('returnOrderStatuses')) {
      for (const status of modules[StatusModuleConfigEntry.ReturnOrderStatuses]) {
        this._returnOrderStatuses.push(status)
      }
    }
  }

  /**
   * @inheritDoc
   */
  public getStatuses (): Array<string> {
    return this._orderStatuses
  }

  /**
   * @inheritDoc
   */
  public getHighlightedStatuses (): Array<string> {
    return this._highlightedOrderStatuses
  }

  /**
   * @inheritDoc
   */
  public getRetryStatuses (): Array<string> {
    return this._retryOrderStatuses
  }

  /**
   * @inheritDoc
   */
  public getReturnStatuses (): Array<string> {
    return this._returnOrderStatuses
  }
}
