
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const SEARCH_RESULT_PRODUCT_ITEM_KEY = 'SearchResultProductItemComponent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface SearchResultProductItemConfig {
  useEnhancedTranslator: boolean
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const SEARCH_RESULT_PRODUCT_ITEM_CONFIG_MAP: SearchResultProductItemConfig = {
  useEnhancedTranslator: false
}
