// Copyright © 2021 Move Closer

import { Ref } from '@vue/composition-api'

import { ComponentObjectPropsOptions } from '../../vue-api'
import { ObjectKeyLiterals, userClassRegistry } from '../use-class-registry'

import { CanHaveTheme, ThemeRegistry, UseThemeClassProvides } from './has-theme.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const canHaveThemeProp: ComponentObjectPropsOptions<CanHaveTheme> = {
  theme: {
    type: String,
    required: false,
    default: ''
  }
}

/**
 * @param theme - Component's `theme` reactive reference.
 * @param themeClassRegistry - registry with class-theme definitions.
 * @param componentName
 * @param strict
 * @param fallbackClass
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const useThemeClass = <T extends ObjectKeyLiterals = string> (
  theme: Ref<T>,
  themeClassRegistry: ThemeRegistry<T>,
  componentName: string,
  strict: boolean = true,
  fallbackClass: string = ''
): UseThemeClassProvides => {
  return {
    themeClass: userClassRegistry(
      theme,
      themeClassRegistry,
      componentName,
      'useTheme',
      strict,
      fallbackClass
    )
  }
}
