import { Injectable, IResponse, ResourceActionFailed } from '@movecloser/front-core'

import { GraphQLConnector } from '../../../../contexts'
import { resolveFromStatus } from '../../../../backoffice/shared/exceptions/connector-errors'

import { INewsletterService } from './newsletter.contracts'

@Injectable()
export class NewsletterService implements INewsletterService {
  private readonly connector: GraphQLConnector

  constructor (connector: GraphQLConnector) {
    this.connector = connector
  }

  public async subscribe (email: string): Promise<void> {
    const response: IResponse = await this.connector.call('newsletter', { email })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response),
        {}
      )
    }
  }
}
