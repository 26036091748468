











import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  AnyObject,
  EventbusType,
  IEventbus,
  IModal,
  ModalPayload,
  ModalState,
  ModalType
} from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { ModalSizeMap } from '../../../../dsl/molecules/Modal'

import { Inject, UI_MODAL_OPENED_EVENT_KEY } from '../../../../support'

import { MODAL_TRANSITION_DURATION } from '../../../../dev-utils/modals'

import { ModalConfig, SwapModalPayload } from './ModalWrapper.contracts'
import { modalWrapperConfig } from './ModalWrapperConfig'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<ModalWrapper>({
  name: 'ModalWrapper',
  mounted (): void {
    this.modalConnector.subscribe((modalState: ModalState) => {
      if (this.modalConnector.isOpened) {
        this.config = {
          ...modalWrapperConfig,
          ...modalState.config
        }
        this.modalSize = modalState.config.size ?? ModalSizeMap.Medium
        this.component = this.modalConnector.getComponent<VueConstructor>(String(this.modalConnector.name))
        this.payload = modalState.payload
      }

      this.open = modalState.opened
    })
  }
})
export class ModalWrapper extends Vue {
  @Inject(EventbusType)
  private readonly eventBus!: IEventbus

  @Inject(ModalType)
  private readonly modalConnector!: IModal

  /**
   * Chosen modal's Vue component.
   */
  public component: VueConstructor | null = null

  /**
   * Chosen modal's config.
   */
  public config: ModalConfig = {} as ModalConfig

  public modalSize: ModalSizeMap = ModalSizeMap.Medium

  /**
   * Determines whether the modal should be open.
   */
  public open: boolean = false

  /**
   * Chosen modal's payload (data).
   */
  public payload: ModalPayload | null = null

  /**
   * Previous modal (if present) with payload
   */
  public previousModal: SwapModalPayload | null = null

  /**
   * Determines whether the modal should be closable.
   */
  public get closable (): boolean {
    if (Object.prototype.hasOwnProperty.call(
      this.config,
      'closable'
    ) && typeof this.config.closable === 'boolean') {
      return this.config.closable
    }

    return true
  }

  /**
   * Handles the `@close` event on the `<D24Modal>`.
   */
  public onClose (): void {
    this.closeModal()
    setTimeout(() => {
      this.clearModalData()
    }, MODAL_TRANSITION_DURATION)
  }

  /**
   * Clears the inner modal's data.
   */
  private clearModalData (): void {
    this.component = null
    this.config = {} as ModalConfig
    this.open = false
    this.payload = null
  }

  /**
   * Closes the modal.
   */
  private closeModal (): void {
    this.modalConnector.close()
  }

  public clickedClose (): void {
    this.closeModal()
    this.previousModal = null
  }

  public clickedOutside (): void {
    if (this.config.closeOnClickOutside ?? true) {
      this.onClose()
    }
  }

  public openPrevious (intersectedData: AnyObject | undefined = undefined): void {
    if (!this.previousModal) {
      this.clickedClose()
      return
    }

    const toOpen: SwapModalPayload = { ...this.previousModal }
    if (intersectedData) {
      toOpen.payload = {
        ...(toOpen.payload ?? {}),
        intersectedData
      }
    }

    this.swapModal(toOpen, true)
  }

  public swapModal (toOpen: SwapModalPayload | null | undefined, forget: boolean = false): void {
    if (!toOpen || typeof toOpen !== 'object' || Object.keys(toOpen).length < 1 || !toOpen.toOpen.length) {
      this.clickedClose()
      return
    }

    if (this.open && !forget) {
      this.previousModal = {
        toOpen: `${this.modalConnector.name}`,
        payload: !this.payload ? null : { ...this.payload, ...(toOpen.override ?? {}) },
        config: !this.config ? undefined : { ...this.config }
      }
    }

    this.closeModal()
    setTimeout(() => this.modalConnector.open(toOpen.toOpen, toOpen.payload, toOpen.config), 200)
  }

  @Watch('open')
  protected onIsOpenUpdate (value: boolean): void {
    if (value) {
      this.eventBus.emit(UI_MODAL_OPENED_EVENT_KEY, { name: this.component?.name })
    }
  }
}

export default ModalWrapper
