













import { Component, Vue } from 'vue-property-decorator'

/**
 * A generic error page, rendered when something has gone wrong.
 */
@Component<ErrorView>({
  name: 'ErrorView',
  metaInfo () {
    return {
      title: this.$t('modules.root.views.ErrorView.meta.title') as string
    }
  }
})
export class ErrorView extends Vue {}

export default ErrorView
