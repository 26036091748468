
import { Component } from 'vue-property-decorator'
import { Inject, logger } from '../../../support'
import { StructureConfigurable } from '../../../support/mixins'
import { IUserComService, UserComServiceType } from '../services'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<InitUserComMixin>({
  name: 'InitUserComMixin',
  created () {
    this.init()
  }
})
export class InitUserComMixin extends StructureConfigurable {
  @Inject(UserComServiceType)
  protected readonly userComService?: IUserComService

  /**
   * Loads badge UserCom aggregate rating data
   * @protected
   */
  protected async init (): Promise<void> {
    if (!this.userComService) {
      return
    }

    try {
      await this.userComService.init()
    } catch (e) {
      logger(e, 'error')
    }
  }
}
