
import { IConnector, Injectable, IResponse, ResourceActionFailed } from '@movecloser/front-core'

import { resolveFromStatus } from '../../../../backoffice/shared/exceptions/connector-errors'

import { ITrustedShop, ReviewsResponseData, ReviewType, TrustedReviewsTotalCountData } from './contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Injectable()
export class TrustedShopRepository implements ITrustedShop {
  protected connector: IConnector
  protected DEFAULT_REVIEWS_COUNT = '50'
  protected DEFAULT_REVIEWS_SORT = 'submittedAt'

  constructor (connector: IConnector) {
    this.connector = connector
  }

  /**
   * Retrieves a list of trusted shop reviews
   */
  public async getReviewsBySku (sku: string, count?: string): Promise<ReviewsResponseData> {
    const response: IResponse = await this.connector.call('trustedShop', 'reviews', {}, { query: sku, count })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }

    return response.data as ReviewsResponseData
  }

  /**
   * Retrieves a total number of reviews for a specific sku
   */
  public async getReviewsCountForSku (sku: string): Promise<TrustedReviewsTotalCountData> {
    const response: IResponse = await this.connector.call('trustedShop', 'reviewsCount', {}, { query: sku })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }

    return response.data as TrustedReviewsTotalCountData
  }

  /**
   * Retrieves a list of service reviews
   */
  public async getServiceReviews (count: string = this.DEFAULT_REVIEWS_COUNT, orderBy: string = this.DEFAULT_REVIEWS_SORT): Promise<ReviewsResponseData> {
    const response: IResponse = await this.connector.call('trustedShop', 'reviews', {}, { count, orderBy })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }

    return this.filterServiceReviews(response.data as ReviewsResponseData)
  }

  /**
   * Filters reviews that refer to the service
   * @param data
   * @private
   */
  private filterServiceReviews (data: ReviewsResponseData): ReviewsResponseData {
    return {
      ...data,
      items: data.items.filter((item) => item.type === ReviewType.ServiceReview)
    }
  }
}
