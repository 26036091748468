// Copyright © 2022 Move Closer

import { HasSize } from '../../composables'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export enum ModalSizeMap {
  Full = 'full',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  XLarge = 'xlarge'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
export interface ModalProps extends HasSize {
  closeOnClickOutside?: boolean
  hasBackdrop?: boolean
  isOpen: boolean
  isScrollable?: boolean
}
