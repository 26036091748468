// Copyright © 2022 Move Closer

import 'reflect-metadata'
import { ComponentOptions, VueConstructor } from 'vue'
import { createDecorator } from 'vue-class-component'
import { interfaces } from 'inversify'
import { getCurrentInstance } from '@vue/composition-api'

import { logger } from '../logger'

let Vue: VueConstructor

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const Inject = (identifier?: interfaces.ServiceIdentifier<unknown>, strict?: boolean) =>
  (proto: Vue, key: string): void => {
    let Type: unknown

    if (typeof Reflect !== 'undefined' && typeof Reflect.getMetadata === 'function') {
      Type = Reflect.getMetadata('design:type', proto, key)
    }

    if (typeof strict === 'undefined') {
      strict = true
    }

    return createDecorator((options: ComponentOptions<Vue>, key) => {
      options.computed = options.computed || {}

      options.computed[key] = function (this: Vue) {
        if (typeof this.$container === 'undefined') {
          logger(`FATAL ERROR! [this.$container] is undefined! Failed to inject the [${identifier || Type}]!`, 'error')
          return undefined
        }

        try {
          return this.$container.get(identifier || Type)
        } catch (e) {
          if (strict) {
            throw e
          }

          return undefined
        }
      }
    })(proto, key)
  }

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const resolve = <T> (identifier: symbol): T => {
  const app = getCurrentInstance()
  if (!app?.proxy.$container) {
    return null as unknown as T
  }

  return app.proxy.$container.get(identifier)
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function initConfiguration (vue: Vue): void {
  if (vue.$options.configuration) {
    vue.$configuration = vue.$options.configuration
  } else if (vue.$options.parent && vue.$options.parent.$configuration) {
    vue.$configuration = vue.$options.parent.$configuration
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function initInversify (vue: Vue): void {
  if (vue.$options.container) {
    vue.$container = vue.$options.container
  } else if (vue.$options.parent && vue.$options.parent.$container) {
    vue.$container = vue.$options.parent.$container
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function install (_Vue: VueConstructor): void {
  if (Vue && _Vue === Vue) {
    if (process.env.VUE_APP_ENV !== 'production') {
      throw new Error('[Front Core] already installed.')
    }
  } else {
    _Vue.mixin({
      beforeCreate (this: Vue): void {
        initConfiguration(this)
        initInversify(this)
      }
    })
  }
}

export { install as default, install as FrontCore }
