import { AnyObject } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface IStoreConfigService {
  /**
   * Loads the store setting
   */
  loadConfig (): Promise<void>

  /**
   * Determines the full configuration object of `_config`
   */
  getConfig(): AnyObject

  /**
   * Gets the requested values by passed-in key
   */
  getConfigValueByKey<T extends string | object | number = string>(key: string): T
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const StoreConfigServiceType = Symbol.for('IStoreConfigService')
