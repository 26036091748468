// Copyright © 2021 Move Closer

import { NavigationGuardNext, Route } from 'vue-router'

import { UVuePlugin } from '@contract/uvue-plugin'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const ErrorClearPlugin: UVuePlugin = {
  async beforeStart (context): Promise<void> {
    const { error, router } = context

    router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
      error.clear()
      next()
    })
  }
}

export default ErrorClearPlugin
