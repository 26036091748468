import { Model } from '@movecloser/front-core'
import { WishListData } from '../contract'

export class Wishlist extends Model<WishListData> {
  protected boot (): void {
    this.initialValues = {}
    this.modelProperties = [
      'id',
      'name',
      'itemsCount',
      'items'
    ]
  }
}
