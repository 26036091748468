import { MappingConfig } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const storeConfigMappingConfig: MappingConfig = {
  id: 'id',
  storeCode: 'store_code',
  storeName: 'store_name',
  locale: 'locale',
  baseCurrencyCode: 'base_currency_code',
  defaultDisplayCurrencyCode: 'default_display_currency_code',
  isDefaultStore: 'is_default_store'
}
