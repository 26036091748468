
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const TrustedShopRepositoryType = Symbol.for('ITrustedShop')

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum ReviewType {
  ProductReview = 'PRODUCT_REVIEW',
  ServiceReview = 'SERVICE_REVIEW'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface ITrustedShop {
  getReviewsBySku (sku: string, count?: string): Promise<ReviewsResponseData>
  getReviewsCountForSku (sku: string): Promise<TrustedReviewsTotalCountData>
  getServiceReviews (count?: string, orderBy?: string): Promise<ReviewsResponseData>
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface ReviewsResponseData {
  items: Array<TrustedShopReview>
  paging: TrustedReviewsPaging
  totalElements: number
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface TrustedReviewsPaging {
  count: number
  cursor: {
    after: string
    before: string
  }
  links: {
    next: string
    previous: string
  }
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface TrustedReviewsTotalCountData {
  accountId: string
  totalElements: number
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface TrustedShopReview {
  accountRef: string
  channelRef: string
  comment: string
  createdAt: string
  customer: {
    firstName: string
    lastName: string
    fullName: string
    email: string
  }
  event: {
    id: string
    type: string
  }
  feedbackType: string
  hasAttachments: boolean
  id: string
  inviteRef: string
  object: string
  product?: {
    brand: string
    gtin: string
    id: string
    imageUrl: string
    name: string
    sku: string
    url: string
  }
  questionnaire: AnyObject
  rating: number
  state: string
  submittedAt: string
  surveyData: Array<AnyObject>
  title: string
  transaction: {
    reference: string
  }
  type: string
  updatedAt: string
  verificationType: string
  _object: string
}
