// Copyright © 2022 Move Closer

/**
 * Determines whether the given URL points to the file, instead of a resource.
 *
 * @param url - The URL to check.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const isUrlFile = (url: string): boolean => {
  if (typeof url !== 'string') {
    throw new Error(`isUrlFile(): Expected the [url] argument to be a type of [string], but got [${typeof url}]!`)
  }

  // Firstly, check if there is ANY chance for the file-like phrase in the given URL. If not, we can immediately return
  // `false`, but if the file-like phrase WAS found in the string, we have to dig deeper (see: `parts`).
  if (!/\.[^.\\/:*?"<>|\r\n]+$/g.test(url)) {
    return false
  }

  /**
   * The `url` argument spread in two, different parts:
   *   1. the actual **URL** (pathname);
   *   and
   *   2. the **query params**.
   */
  const parts = url.match(/(?<url>[^?]+)(?<query>\?.*)?/g)

  if (parts === null) {
    return false
  }

  /**
   * The URL (pathname) poked from the `url` argument
   */
  const urlPart: string = parts[1]

  return /\.[^.\\/:*?"<>|\r\n]+$/g.test(urlPart)
}
