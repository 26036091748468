
/**
 * Replaces snake_case order status with camelCase one
 * @param item
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const translateToCamelCase = (item: string) => {
  return item.split('_').map((el: string, index: number) => {
    if (index === 0) {
      return el
    } else {
      return el.charAt(0).toUpperCase() + el.slice(1)
    }
  }).join('')
}
