// Copyright © 2021 Move Closer

import { ComputedRef, computed, PropType, Ref } from '@vue/composition-api'

import { ComponentObjectPropsOptions, Emit } from '../../vue-api'

import { HasErrors } from './has-errors.contracts'

/**
 * Props partial.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const hasErrorsProp: ComponentObjectPropsOptions<HasErrors> = {
  errors: {
    type: Array as PropType<string[]>,
    required: false,
    default: () => []
  }
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface UseErrorsProvides {
  /**
   * Reference to `errors` property of component.
   */
  errors: Ref<string[]>

  /**
   * Cleans the component from errors by flushing `errors` ref.
   */
  clearErrors(): void

  /**
   * Determines whether any error is present.
   */
  hasErrors: ComputedRef<boolean>
}

/**
 * @param errors - Component's `errors` reactive reference.
 * @param emit - `Emit` property of the context.
 *
 * @author Łukasz Sitnicki <łukasz.sitnicki@movecloser.pl>
 */
export const useErrors = (errors: Ref<string[]>, emit: Emit): UseErrorsProvides => {
  const clearErrors = (): void => {
    if (errors.value && errors.value.length > 0) {
      emit('update:errors', [])
    }
  }

  const hasErrors = computed<boolean>(() => Array.isArray(errors.value) && errors.value.length > 0)

  /**
   * Determines whether the control has any validation errors.
   */
  return { clearErrors, errors, hasErrors }
}
