// Copyright © 2021 Move Closer

import { AppConfig } from '@movecloser/front-core'

import { COMPONENTS_STRUCTURE_CONFIG, LOCALE_CONTENT_MANAGER_CONFIG, MagentoVersion, PRIVACY_CONFIG } from '@core'

import { analytics } from './analytics'
import { AppModules as modules } from './modules'
import { appQueries as queries } from './queries'
import { configuration } from './components'
import { http } from './http'
import { localesRules } from '@config/localesRules'
import { middlewares } from './middlewares'
import { modalsRegistry as modals } from './modal'
import { patchesRegistry as patches } from './patches'
import { privacy } from './privacy'
import { resources } from './resources'
import { ROUTER_DRIVER as router } from './router'
import { SEARCH_CONFIG, searchConfig } from './search'
import { services } from './services'
import { STORE_DRIVER as store } from './store'
import { wishlist } from './wishlist'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const config: AppConfig = {
  analytics,
  [COMPONENTS_STRUCTURE_CONFIG]: configuration,
  http,
  [LOCALE_CONTENT_MANAGER_CONFIG]: localesRules,
  magentoVersion: MagentoVersion.Lirene,
  middlewares,
  modals,
  modules,
  nonProductionEnvironments: ['local'],
  patches,
  [PRIVACY_CONFIG]: privacy,
  queries,
  resources,
  router,
  [SEARCH_CONFIG]: searchConfig,
  services,
  store,
  wishlist,
  // Has to be defined, even if it's empty, or an error will be thrown.
  modalConfig: {}
}
