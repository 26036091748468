


import { Component, Vue } from 'vue-property-decorator'

import { AllowedThemeSchema } from '@core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'HasTheme'
})
export class HasTheme extends Vue {
  public get theme (): string {
    const result = this.$store.getters['content/response']

    if (result === null || result.theme === AllowedThemeSchema.Default) {
      return ''
    }

    return `--themes-${result.theme}`
  }
}

export default HasTheme
