















import { Component, Mixins } from 'vue-property-decorator'
import { Authentication, AuthServiceType } from '@movecloser/front-core'

import { Inject } from '../../../../support'

import { NavItemProps } from '../../../../dsl/molecules/Nav'
import { StructureConfigurable } from '../../../../support/mixins'

import { LogoutMixin, UserModel } from '../../../auth/shared'

import { UserMixin } from '../../shared'

import { Service, ProfileTabsServiceType } from '../../services/profile-tabs'

import { translateProfileElementToProfileTabsProps } from './ProfileWrapper.helpers'
import {
  PROFILE_NAV_TAB_ACTIVE_ELEMENT_CLASS,
  PROFILE_NAV_TAB_LIST_ELEMENT,
  PROFILE_WRAPPER_COMPONENT_CONFIG_MAP, PROFILE_WRAPPER_COMPONENT_KEY,
  ProfileWrapperComponentConfig
} from './ProfileWrapper.config'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<ProfileWrapper>({
  name: 'ProfileWrapper',
  created () {
    this.config = this.getComponentConfig(PROFILE_WRAPPER_COMPONENT_KEY, { ...PROFILE_WRAPPER_COMPONENT_CONFIG_MAP })
  },
  mounted () {
    this.scrollToActiveTab()
  }
})
export class ProfileWrapper extends Mixins(LogoutMixin, UserMixin, StructureConfigurable) {
  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(ProfileTabsServiceType)
  protected readonly profileTabsService!: Service

  protected config!: ProfileWrapperComponentConfig

  /**
   * Check if application should render constant welcome heading (e.g. Jan, witaj ponownie!) with immutable place in DOM
   */
  public get hasWelcomeHeadingConfig (): boolean {
    return this.getConfigProperty<boolean>('hasWelcomeHeading')
  }

  public get containerClass (): string[] {
    return this.getConfigProperty<string[]>('containerClass')
  }

  public get containerListClass (): string[] {
    return this.getConfigProperty<string[]>('containerListClass')
  }

  public get shouldCenterNavigationElements (): boolean {
    return this.getConfigProperty<boolean>('shouldCenterNavigationElements')
  }

  /**
   * Create profile tabs.
   */
  public get navLinks (): NavItemProps[] {
    return [
      ...translateProfileElementToProfileTabsProps(
        this.profileTabsService.getTabs(),
        this.$i18n,
        this.$router
      ),
      {
        label: this.$t('front.profile.views.productWrapper.logout').toString(),
        onClick: () => {
          this.logout()
        },
        target: '/'
      }
    ]
  }

  /**
   * Scrolls to currently active nav tab
   */
  public scrollToActiveTab () {
    const list = document.querySelector(PROFILE_NAV_TAB_LIST_ELEMENT)

    if (list && this.shouldCenterNavigationElements) {
      for (const element of list.children) {
        for (const child of element.children) {
          if (Object.values(child.classList).includes(PROFILE_NAV_TAB_ACTIVE_ELEMENT_CLASS)) {
            list.scrollLeft = child.getBoundingClientRect().x
          }
        }
      }
    }
  }
}

export default ProfileWrapper
