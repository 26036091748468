


import { AbstractModule } from '@movecloser/page-builder'
import { defineComponent } from '@vue/composition-api'

import {
  AnyModule,
  logger,
  ModuleConstructor,
  ModuleDriver,
  modulesUiRegistry,
  ModulesUiRegistryEntry
} from '@core'

import { MissingModule } from './MissingModule.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const Module = defineComponent({
  name: 'Module',
  extends: AbstractModule,
  computed: {
    resolveInnerComponent (): ModuleConstructor {
      const driver: ModuleDriver = (this.resolvedModule as AnyModule).driver
      const component: ModulesUiRegistryEntry | undefined = modulesUiRegistry[driver]

      if (typeof component === 'undefined') {
        logger(`[Module]: Failed to resolve the UI component for the [${driver}] driver.`, 'error')
        return MissingModule
      }

      return component.component
    }
  }
})

export default Module
