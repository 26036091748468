// Copyright © 2021 Move Closer

import { ConnectorMiddleware, FoundResource, Headers, Injectable, Payload } from '@movecloser/front-core'

import { ISiteService, logger, SiteModel } from '@core'

/**
 * Middleware that decorates the API calls with the information about the currently-active domain.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class SiteMiddleware implements ConnectorMiddleware {
  private readonly sites: ISiteService

  constructor (siteService: ISiteService) {
    this.sites = siteService
  }

  // eslint-disable-next-line  @typescript-eslint/no-empty-function
  public afterCall (): void {}

  public beforeCall (resource: FoundResource, headers: Headers, body: Payload): { body: Payload; headers: Headers } {
    if (typeof resource.meta.omitSite === 'boolean' && resource.meta.omitSite) {
      return { body, headers }
    }

    logger(['SiteMiddleware:', resource.url, this.domainHeader])

    return {
      body,
      headers: {
        ...headers,
        ...this.domainHeader
      }
    }
  }

  private get domainHeader (): Headers {
    const site: SiteModel = this.sites.getActiveSite()
    const fallbackLocale = process.env.VUE_APP_HTTP_D24_API_LOCALE || 'en-GB'

    return { 'Accept-Language': site.locale || fallbackLocale, 'X-Site': `${site.id}` }
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const SiteMiddlewareType = Symbol.for('SiteMiddleware')
