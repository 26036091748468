










import { AnyObject } from '@movecloser/front-core'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

import { DisplaySearchResult } from '../../../../contexts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'SearchResultLatestItem' })
export class SearchResultLatestItem extends Vue implements DisplaySearchResult {
  @Prop({ type: Object, required: true })
  public readonly element!: AnyObject

  public get getTarget (): RawLocation {
    return {
      name: 'root.results',
      query: {
        q: this.element.phrase,
        scope: this.element.scope
      }
    }
  }
}
export default SearchResultLatestItem
