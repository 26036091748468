// Copyright © 2021 Move Closer

import { ModalConfig } from './ModalWrapper.contracts'

export const modalWrapperConfig: ModalConfig = {
  closable: true,
  closeOnClickOutside: true,
  hasBackdrop: true,
  isScrollable: false
}
