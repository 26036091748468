// Copyright © 2022 Move Closer

import { Ref } from '@vue/composition-api'

import { ComponentObjectPropsOptions } from '../../vue-api'

import { ObjectKeyLiterals, userClassRegistry } from '../use-class-registry'

import { HasShape, ShapeMap, ShapeRegistry, UseShapeClassProvides } from './has-shape.contracts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const getShapePropDefinition = (defaultShape: string = ShapeMap.Rectangle): ComponentObjectPropsOptions<HasShape> => {
  return {
    shape: {
      type: String,
      required: false,
      default: defaultShape
    }
  }
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const hasShapeProp: ComponentObjectPropsOptions<HasShape> = getShapePropDefinition()

/**
 * @param shape
 * @param shapeClassRegistry
 * @param componentName
 * @param strict
 * @param fallbackClass
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const useShapeClass = <T extends ObjectKeyLiterals = string> (
  shape: Ref<T>,
  shapeClassRegistry: ShapeRegistry<T>,
  componentName: string,
  strict: boolean = false,
  fallbackClass: string = ''
): UseShapeClassProvides => {
  return {
    shapeClass: userClassRegistry(
      shape,
      shapeClassRegistry,
      componentName,
      'useShape',
      strict,
      fallbackClass
    )
  }
}
