








import { defineComponent } from '@vue/composition-api'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const MissingModule = defineComponent({
  name: 'MissingModule',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
})

export default MissingModule
