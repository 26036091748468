








import { Component, Prop, Vue } from 'vue-property-decorator'

import { ProductData } from '../../../../../contexts'

import { ProductCard } from '../../ProductCard'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'SearchResultProductMobile', components: { ProductCard } })
export class SearchResultProductMobile extends Vue {
  @Prop({ type: Object, required: true })
  public readonly product!: ProductData
}
export default SearchResultProductMobile
