// Copyright © 202w Move Closer

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const configuration = {
  structure: {
    AccountView: {
      redirectFromAccountPage: 'orders.me.orders'
    },
    AddedToCartModalComponent: {
      buttonIcon: 'DropIcon'
    },
    ArticlesTeaserModule: {
      buttonIcon: 'DropIcon'
    },
    CartItemComponent: {
      shouldPreserveDeleted: false
    },
    CartSummaryComponent: {
      buttonIcon: 'DropIcon',
      freeDeliveryFrom: 99
    },
    CheckoutView: {
      buttonIcon: 'DropIcon'
    },
    CreationsComponent: {
      showPriceOnLocales: ['pl']
    },
    CustomerDetailsStep: {
      buttonIcon: 'DropIcon'
    },
    GalleryComponent: {
      hasBadges: false
    },
    HeroModule: {
      buttonIcon: 'DropIcon'
    },
    InPostModalComponent: {
      buttonIcon: 'DropIcon'
    },
    LoginFormComponent: {
      buttonIcon: 'DropIcon'
    },
    TopbarComponent: {
      topBarLanguages: [
        {
          url: 'https://lirene.pl/',
          label: 'pl'
        },
        {
          url: 'https://lirene.eu/',
          label: 'en'
        },
        {
          url: 'https://lirene.ua/',
          label: 'ua'
        },
        {
          url: 'https://lirene.com/fr',
          label: 'fr'
        },
        {
          url: 'https://www.lirene.cz/',
          label: 'cz'
        },
        {
          url: 'https://lirene.ru/',
          label: 'ru'
        },
        {
          url: 'https://lirene.hu/',
          label: 'hu'
        },
        {
          url: 'https://lirene.de/',
          label: 'de'
        }
      ]
    },
    NavbarDesktopModule: {
      showCartIconWithoutPrice: true,
      showTotalPrice: false
    },
    NewsletterModule: {
      buttonIcon: 'DropIcon'
    },
    PaymentStep: {
      driversOrder: {
        cashondelivery: 20,
        paynow_gateway: 10,
        banktransfer: 30
      }
    },
    ProductCard: {
      badgeThemes: {
        isNew: 'secondary',
        isSale: 'primary',
        isPromotion: 'primary',
        isBestseller: undefined
      },
      usePreviousPriceAsRegular: true
    },
    ProductHeaderComponent: {
      badgesOnGallery: false,
      badgeThemes: {
        isNew: 'secondary',
        isSale: 'primary',
        isPromotion: 'primary',
        isBestseller: undefined
      },
      favouriteAsIcon: false,
      defaultMaxRating: 5,
      hasCertificates: true,
      hasDefaultVariantSelector: true,
      hasDeliveryTimer: false,
      hasGiftsBox: false,
      hasOrderDetails: false,
      hasVariantDetailsAttributes: false,
      icons: {
        addToCartButtonIcon: 'DropIcon',
        heartEmptyIcon: 'HeartEmptyIcon',
        heartFullIcon: 'HeartFullIcon',
        infoIcon: 'InfoIcon',
        notifyButtonIcon: ''
      },
      promotionBadgeHasLabel: false,
      variantSwitcherShowChosen: false,
      modalSize: 'medium',
      useDrawer: false,
      useVendorReviews: false,
      shouldHaveSeparatedRating: false,
      shouldHaveVisibleRating: true,
      showSingleVariantRating: false,
      isVariantNameEnabled: false,
      isNewBadgeSquare: false, // todo: refactor - badge powinny byc w calosci konfigurowalne
      shouldDisplayApplication: false,
      shouldDisplayReviewWithDescription: true,
      shouldDisplayDiscount: true,
      shouldDisplayRegularPriceForDiscount: false,
      shouldDisplaySimplePrice: true,
      shouldDisplayUnits: true
    },
    ProductsListComponent: {
      buttonIcon: 'DropIcon'
    },
    ProductsTeaserComponent: {
      buttonIcon: 'DropIcon'
    },
    ProfileWrapperComponent: {
      containerListClass: ['container-narrow']
    },
    ProfileView: {
      changeAddressFormComponent: 'simple'
    },
    ShippingStepComponent: {
      buttonIcon: 'DropIcon'
    },
    ShippingsComponent: {
      drivers: [
        'inpost',
        'Glsshipping',
        'freeshipping',
        'Dhlshipping',
        'Dpdshipping'
      ],
      driversOrder: {
        inpost: 10,
        Glsshipping: 20,
        Dpdshipping: 30,
        Dhlshipping: 40,
        freeshipping: 50
      },
      methodsWhenFree: ['inpost', 'Glsshipping']
    },
    Shippings: {
      displayCarrierTitle: true
    },
    SearchResultsComponent: {
      shouldAutoHide: true,
      shouldRelyOnIsOpen: true,
      searchFromChars: 2
    },
    SignupFormComponent: {
      buttonIcon: 'DropIcon'
    },
    ThankYouComponent: {
      buttonIcon: 'DropIcon'
    },
    VariantSwitchComponent: {
      switchIcon: 'DropIcon'
    }
  },
  options: {},
  modules: {
    navLinks: {
      loyaltyTabs: [],
      profileTabs: [
        {
          label: 'myData',
          priority: 70
        }
      ],
      orderTabs: [
        {
          label: 'historyOfOrders',
          priority: 90
        }
      ],
      productTabs: [],
      wishlistTabs: []
    }
  }
}
