// Copyright © 2021 Move Closer

import { IResponse } from '@movecloser/front-core'

export enum ConnectorErrors {
  Conflict = 'conflict',
  Forbidden = 'forbidden',
  NotFound = 'notfound',
  ServerError = 'ServerError',
  Validation = 'validation',
  Unauthenticated = 'unauthenticated',
  Unknown = 'unknown'
}

export function resolveFromStatus (response: IResponse|number): ConnectorErrors {
  switch (typeof response === 'number' ? response : response.status) {
    case 401:
      return ConnectorErrors.Unauthenticated
    case 403:
      return ConnectorErrors.Forbidden
    case 404:
      return ConnectorErrors.NotFound
    case 409:
      return ConnectorErrors.Conflict
    case 422:
      return ConnectorErrors.Validation
    case 500:
      return ConnectorErrors.ServerError
    default:
      return ConnectorErrors.Unknown
  }
}
