
import { AnyObject } from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'

import { COMPONENTS_STRUCTURE_CONFIG, COMPONENTS_STRUCTURE_SUFFIX } from '../providers'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export type ComponentsStructureConfig = Record<string, Record<string, string | boolean | number>> | AnyObject

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component
export class StructureConfigurable extends Vue {
  protected defaultConfig: AnyObject = {}
  protected config: AnyObject = {}

  protected getComponentConfig<C = AnyObject> (key: string, defaultConfig?: C): C {
    const configCandidate: AnyObject | null | undefined = this.$configuration?.byKey(
      `${COMPONENTS_STRUCTURE_CONFIG}.${COMPONENTS_STRUCTURE_SUFFIX}.${key}`,
      false,
      defaultConfig
    )

    if (!configCandidate) {
      return defaultConfig as C
    }

    this.defaultConfig = defaultConfig as AnyObject

    return {
      ...defaultConfig,
      ...configCandidate
    } as C
  }

  protected getConfigProperty<C = AnyObject> (key: string, config = this.config, fallbackValue?: C): C {
    const isKeyDefined = config.hasOwnProperty(key)
    if (!isKeyDefined) {
      return fallbackValue ?? this.defaultConfig[key] as C
    }

    return config[key] as C
  }
}

export default StructureConfigurable
