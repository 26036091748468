// Copyright © 2021 Move Closer

import { PropOptions, Ref } from '@vue/composition-api'

import { ComponentObjectPropsOptions } from '../../vue-api'
import { ObjectKeyLiterals, userClassRegistry } from '../use-class-registry'

import { HasSize, SizeMap, SizeRegistry, UseSizeClassProvides } from './has-size.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const getSizePropDefinition = (defaultSize = SizeMap.Medium): PropOptions => {
  return {
    type: String,
    required: false,
    default: defaultSize
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const hasSizeProp: ComponentObjectPropsOptions<HasSize> = {
  size: getSizePropDefinition()
}

/**
 * @param size - Component's `size` reactive reference.
 * @param sizeClassRegistry - registry that should be used
 *  to determine the applicable CSS class.
 * @param componentName
 * @param strict
 * @param fallbackClass
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const useSizeClass = <T extends ObjectKeyLiterals = string>(
  size: Ref<T>,
  sizeClassRegistry: SizeRegistry<T>,
  componentName: string,
  strict: boolean = true,
  fallbackClass: string = ''
): UseSizeClassProvides => {
  return {
    sizeClass: userClassRegistry(
      size,
      sizeClassRegistry,
      componentName,
      'useSize',
      strict,
      fallbackClass
    )
  }
}
