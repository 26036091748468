// Copyright © 2022 Move Closer
import { ComponentInternalInstance, toRefs, watch } from '@vue/composition-api'

import { ComponentObjectPropsOptions } from '../../vue-api'
import { hasSizeProp, useSizeClass } from '../../composables'

import { ModalProps } from './Modal.contracts'
import { sizeClassRegistry } from './Modal.config'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
export const modalProps: ComponentObjectPropsOptions<ModalProps> = {
  ...hasSizeProp,

  closeOnClickOutside: {
    type: Boolean,
    required: false,
    default: true
  },

  hasBackdrop: {
    type: Boolean,
    required: false,
    default: true
  },

  isOpen: {
    type: Boolean,
    required: false,
    default: false
  },

  isScrollable: {
    type: Boolean,
    required: false,
    default: false
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
export const useModal = (
  props: ModalProps,
  internalInstance: ComponentInternalInstance | null
) => {
  if (internalInstance === null) {
    throw new Error('useLink(): FATAL ERROR! Failed to resolve the component instance!')
  }

  const { type, emit } = internalInstance
  const { size } = toRefs(props)

  const { sizeClass } = useSizeClass<string>(
    size,
    sizeClassRegistry,
    `${type._componentTag}`
  )

  const close = () => {
    emit('close')
  }

  watch(() => props.isOpen, () => {
    switch (props.isOpen) {
      case true:
        if (typeof window !== 'undefined') {
          const scrollY = window.scrollY
          document.body.classList.add('scroll--locked')
          document.body.style.top = `-${scrollY}px`
        }
        break
      case false:
        if (typeof window !== 'undefined') {
          document.body.classList.remove('scroll--locked')
          const scrollY = document.body.style.top
          document.body.style.top = ''
          window.scrollTo({
            left: 0,
            top: parseInt(scrollY || '0') * -1,
            // @ts-expect-error tslint doesn't allow 'instant' (even though it is valid according to spec)
            behavior: 'instant'
          })
        }
    }
  })

  return { close, sizeClass }
}
