// Copyright © 2023 Move Closer

import { ImageData, ModuleContentResolver } from '../../contexts'
import { logger } from '../../support'

import { resolveImage } from '../resolvers'

import { WhereToBuyModuleContent, WhereToBuyModuleContentInput } from './WhereToBuy.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const resolveWhereToBuyModuleContent: ModuleContentResolver<WhereToBuyModuleContentInput, WhereToBuyModuleContent> =
  async (input, relatedService): Promise<WhereToBuyModuleContent> => {
    const resolvedBrands: Array<{ image: ImageData; url?: string }> = []

    if (input.brands && Array.isArray(input.brands) && input.brands.length > 0) {
      try {
        for (const brand of input.brands) {
          const resolved = await resolveImage(brand.image, relatedService)
          resolvedBrands.push({
            url: brand.url,
            image: resolved
          })
        }
      } catch (e) {
        logger('Error while resolving brands', 'error')
      }
    }

    return {
      ...input,
      brands: resolvedBrands.length ? resolvedBrands : undefined
    }
  }
