import { MappingConfig, MappingTypes } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const wishlistAdapter: MappingConfig = {
  id: 'id',
  name: 'name',
  itemsCount: {
    type: MappingTypes.Function,
    value: (value: any) => {
      if (typeof value.items_count === 'undefined') {
        return value.items_v2.items.length
      }

      return value.items_count
    }
  },
  items: {
    type: MappingTypes.Function,
    value: (value: any) => {
      return value.items_v2.items.map((item: any) => {
        return {
          id: item.id,
          quantity: item.quantity,
          product: {
            uid: item.product.uid,
            name: item.product.name,
            sku: item.product.sku
          }
        }
      })
    }
  }
}
