// Copyright © 2022 Move Closer

import { NavigationGuardNext, Route } from 'vue-router'
import { AnyObject, RoutesFactory, RoutesStack } from '@movecloser/front-core'

import { buildStaticRoutesDefinitions, StaticRouteDefinition } from '../../contexts'

import { ILocaleContentManager, LocaleContentManagerType } from '../shared/services'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum RouteName {
  Cart = 'cart',
  Checkout = 'checkout',
  PaymentError = 'result.error',
  PaymentVerification = 'result.verify',
  ThankYou = 'result.success'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const checkoutModuleStaticRoutes: StaticRouteDefinition[] = buildStaticRoutesDefinitions(
  'checkout',
  Object.values(RouteName)
)

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const checkoutRoutes: RoutesFactory = (container, configuration): RoutesStack => {
  return [
    {
      path: '/cart',
      name: RouteName.Cart,
      component: () => import(/* webpackChunkName: "cart/view" */ './views/cart/Cart.vue'),
      beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
        const canProceed = container.get<ILocaleContentManager>(LocaleContentManagerType).retrieve<boolean>('cart')

        if (canProceed) {
          return next()
        }

        next({ path: '/' })
      }
    },
    {
      path: '/checkout',
      name: RouteName.Checkout,
      component: () => {
        const components = configuration.byKey<AnyObject>('components')

        if (!components || !components.structure.CheckoutView || !components.structure.CheckoutView.componentName) {
          return import(/* webpackChunkName: "checkout/view" */ './views/Checkout.vue')
        }

        return import(/* webpackChunkName: "checkout/view" */ `./views/${components.structure.CheckoutView.componentName}.vue`)
      },
      beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
        const canProceed = container.get<ILocaleContentManager>(LocaleContentManagerType).retrieve<boolean>('cart')

        if (canProceed) {
          return next()
        }

        next({ path: '/' })
      }
    },
    {
      path: '/thank-you',
      name: RouteName.ThankYou,
      component: () => import(/* webpackChunkName: "payment/view" */ './views/ThankYou.vue'),
      beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
        const canProceed = container.get<ILocaleContentManager>(LocaleContentManagerType).retrieve<boolean>('cart')

        if (canProceed) {
          return next()
        }

        next({ path: '/' })
      }
    },
    {
      path: '/payment-error/:code?',
      name: RouteName.PaymentError,
      component: () => import(/* webpackChunkName: "payment/view" */ './views/PaymentError.vue'),
      beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
        const canProceed = container.get<ILocaleContentManager>(LocaleContentManagerType).retrieve<boolean>('cart')

        if (canProceed) {
          return next()
        }

        next({ path: '/' })
      }
    },
    {
      path: '/payment-verification',
      name: RouteName.PaymentVerification,
      component: () => import(/* webpackChunkName: "payment/view" */ './views/PaymentReturn.vue'),
      beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
        const canProceed = container.get<ILocaleContentManager>(LocaleContentManagerType).retrieve<boolean>('cart')

        if (canProceed) {
          return next()
        }

        next({ path: '/' })
      }
    },
    {
      path: '/payment-return',
      redirect: (to: Route) => ({
        name: `checkout.${RouteName.PaymentVerification}`,
        params: to.params,
        query: to.query
      }),
      meta: { static: false },
      beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
        const canProceed = container.get<ILocaleContentManager>(LocaleContentManagerType).retrieve<boolean>('cart')

        if (canProceed) {
          return next()
        }

        next({ path: '/' })
      }
    },
    {
      // TODO: Remove this route if return url changed in Mage.
      path: '/payu-return',
      redirect: (to: Route) => ({
        name: `checkout.${RouteName.PaymentVerification}`,
        params: to.params,
        query: to.query
      }),
      meta: { static: false },
      beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
        const canProceed = container.get<ILocaleContentManager>(LocaleContentManagerType).retrieve<boolean>('cart')

        if (canProceed) {
          return next()
        }

        next({ path: '/' })
      }
    }
  ]
}
