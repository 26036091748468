import { WishListModel } from './models'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface WishlistState {
  wishlist: WishListModel | null
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum MutationType {
  SetWishlist = 'setWishList'
}
