
/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export interface INewsletterService {
  subscribe (email:string): Promise<void>
}

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const NewsletterServiceType = Symbol.for('INewsletterService')
