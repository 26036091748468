import gql from 'graphql-tag'

import { QueryRegistry } from '../../../contexts'

import { CREATE_WISHLIST_FRAGMENT, CUSTOMER_WISHLIST_FRAGMENT } from './graphql/fragments'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const wishListQueries: QueryRegistry = {
  add: {
    type: 'mutation',
    query: gql`
        ${CUSTOMER_WISHLIST_FRAGMENT}
        mutation CreateWishList ($wishlistId: ID!, $wishlistItems: [WishlistItemInput!]!) {
            addProductsToWishlist(wishlistId: $wishlistId, wishlistItems: $wishlistItems) {
                wishlist {
                    ...CustomerWishList
                }
            }
        }
    `,
    meta: {
      noAuth: false
    }
  },
  create: {
    type: 'mutation',
    query: gql`
      ${CREATE_WISHLIST_FRAGMENT}
      query CreateWishList {
          wishlist {
              ...CreateWishListFragment
          }
      }
    `,
    meta: {
      noAuth: false
    }
  },

  fetch: {
    type: 'query',
    query: gql`
        ${CUSTOMER_WISHLIST_FRAGMENT}
        query CustomerWishList {
            customer {
                wishlists {
                    ...CustomerWishList
                }
            }
        }
    `,
    meta: {
      noAuth: false
    }
  },

  remove: {
    type: 'mutation',
    query: gql`
        ${CUSTOMER_WISHLIST_FRAGMENT}
        mutation RemoveProductsFromWishlist ($wishlistId: ID!, $wishlistItemsIds: [ID!]!) {
            removeProductsFromWishlist(wishlistId: $wishlistId, wishlistItemsIds: $wishlistItemsIds) {
                wishlist {
                    ...CustomerWishList
                }
            }
        }
    `,
    meta: {
      noAuth: false
    }
  }
}
