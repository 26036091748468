import { AnyObject, Authentication, Injectable, mapModel, ResourceActionFailed } from '@movecloser/front-core'

import { resolveFromStatus } from '../../../../support'
import { IGraphQL } from '../../../../contexts'

import { User } from '../../../profile/models/user'

import {
  CreateWishlistInput,
  WishListItem,
  WishListItemInput,
  WishListModel
} from '../../contract'

import { IWishList } from './contracts'
import { Wishlist, wishlistAdapter } from '../../models'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Injectable()
export class WishListService implements IWishList {
  private readonly connector: IGraphQL
  private readonly authService: Authentication<User>

  protected localWishlist: WishListModel | null = null

  constructor (connector: IGraphQL, authService: Authentication<User>) {
    this.connector = connector
    this.authService = authService
  }

  /**
   * @inheritDoc
   */
  public async add (id: string, item: WishListItemInput): Promise<WishListModel> {
    if (this.isGuest()) {
      return new Promise((resolve) => {
        try {
          this.resolveLocalWishlist()

          const wishlist = {
            id: this.localWishlist?.id,
            itemsCount: this.localWishlist?.itemsCount,
            items_v2: {
              items: [
                ...this.localWishlist?.items ?? [],
                {
                  id: Math.floor(Math.random() * 999).toString(),
                  product: {
                    name: '---', // this property is not used in ui
                    sku: item.sku,
                    uid: Math.floor(Math.random() * 999).toString()
                  },
                  quantity: item.quantity ?? 1
                }]
            }
          }

          localStorage.setItem('wishlist', JSON.stringify(wishlist))

          resolve(Wishlist.hydrate<WishListModel>(mapModel(wishlist, wishlistAdapter, false)))
        } catch (e) {
          throw new ResourceActionFailed(
            (e as Error).message,
            500,
            {}
          )
        }
      })
    }

    const response = await this.connector.call('add', { wishlistId: id, wishlistItems: [item] })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return Wishlist.hydrate<WishListModel>(mapModel(
      response.data.addProductsToWishlist.wishlist,
      wishlistAdapter,
      false
    ))
  }

  /**
   * @inheritDoc
   */
  public async create (input?: CreateWishlistInput, context: 'user' | 'guest' = 'guest'): Promise<AnyObject> {
    if (context === 'user') {
      return await this.createForCustomer()
    } else {
      return await this.createForGuest()
    }
  }

  /**
   * @inheritDoc
   */
  public async fetch (): Promise<WishListModel> {
    if (this.isGuest()) {
      return new Promise((resolve) => {
        if (this.localWishlist) {
          resolve(this.localWishlist)
        }

        this.createForGuest().then((wishlist) => {
          resolve(mapModel(wishlist, wishlistAdapter, false))
        })
      })
    }

    const response = await this.connector.call('fetch', {})
    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return Wishlist.hydrate<WishListModel>(mapModel(response.data.customer.wishlists[0], wishlistAdapter, false))
  }

  /**
   * @inheritDoc
   */
  public async remove (id: string, itemId: string): Promise<WishListModel> {
    if (this.isGuest()) {
      return new Promise((resolve) => {
        try {
          this.resolveLocalWishlist()

          const items = this.localWishlist!.items.filter((item: WishListItem) => item.id !== itemId)

          const wishlist = {
            id: this.localWishlist?.id,
            items_v2: {
              items: items
            }
          }

          localStorage.setItem('wishlist', JSON.stringify(wishlist))

          resolve(Wishlist.hydrate<WishListModel>(mapModel(wishlist, wishlistAdapter, false)))
        } catch (e) {
          throw new ResourceActionFailed(
            (e as Error).message,
            500,
            {}
          )
        }
      })
    }

    const response = await this.connector.call('remove', { wishlistId: id, wishlistItemsIds: [itemId] })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return Wishlist.hydrate<WishListModel>(mapModel(
      response.data.removeProductsFromWishlist.wishlist,
      wishlistAdapter,
      false
    ))
  }

  /**
   * TODO: **DEPRECATED** - Each customer already has a wishlist, no need to create new one.
   *       Adobe Commerce do allow to have multiple wishlists, in case if you want to develop
   *       this feature, correct the below function (it requires proper inout).
   */
  private async createForCustomer (input?: CreateWishlistInput): Promise<WishListModel> {
    const response = await this.connector.call('create', input ?? {})

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return Wishlist.hydrate<WishListModel>(mapModel(response.data.wishlist, wishlistAdapter, false))
  }

  /**
   * Creates new wishlist for guest
   *
   * NOTE!: This function only imitates the wishlist. As of Magento does not allow us to
   *        create wishlist instance for not logged in customers manually creating new one
   *        was required so that guest will have their own.
   */
  private async createForGuest (): Promise<AnyObject> {
    return new Promise<AnyObject>(resolve => {
      // This object has a valid structure according to Magento.
      const candidate: AnyObject = {
        id: String(Math.floor(Math.random() * 9)),
        itemsCount: undefined,
        items_v2: {
          items: []
        }
      }

      resolve(candidate)
    })
  }

  /**
   * Determines whether context is user or guest.
   */
  private isGuest (): boolean {
    return !this.authService.check()
  }

  /**
   * Resolves the local wishlist for the guest.
   */
  public resolveLocalWishlist (): void {
    const wishlistCandidate = localStorage.getItem('wishlist')

    if (!wishlistCandidate || !WishListService.isValidWishlistObject(JSON.parse(wishlistCandidate))) {
      throw new Error('Cannot resolve local wishlist. Either wishlist does not exists or has invalid structure!')
    }

    this.localWishlist = Wishlist.hydrate<WishListModel>(mapModel(JSON.parse(wishlistCandidate), wishlistAdapter, false))
  }

  /**
   * Checks whether wishlist has a valid payload to be processed.
   *
   * @param toCheck - wishlist object
   */
  public static isValidWishlistObject (toCheck: unknown): boolean {
    if (typeof toCheck === 'string' || typeof toCheck === 'number' || Array.isArray(toCheck)) {
      return false
    }

    if (typeof toCheck === 'object') {
      if (Object.prototype.hasOwnProperty.call(toCheck, 'id') && Object.prototype.hasOwnProperty.call(
        toCheck,
        'items_v2'
      )) {
        return true
      }
    }

    return false
  }
}
