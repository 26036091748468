import { Component, Mixins, Watch } from 'vue-property-decorator'
import { ResourceActionFailed } from '@movecloser/front-core'

import { Inject, logger } from '../../../../support'

import { AuthMixin } from '../../../auth/shared'

import { IWishList, WishListServiceType } from '../../services/wishlist'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<InitWishListMixin>({
  name: 'InitWishListMixin',
  created (): void {
    try {
      this.wishListService?.resolveLocalWishlist()
    } catch (e) {
      this.initWishList()
    }

    this.fetchWishList()
  }
})
export class InitWishListMixin extends Mixins<AuthMixin>(AuthMixin) {
  @Inject(WishListServiceType, false)
  protected readonly wishListService?: IWishList

  /**
   * Initializes the wishlist service.
   */
  protected async initWishList (): Promise<void> {
    if (!this.isServiceAvailable()) return

    await this.createWishListOnMount()
    await this.fetchWishList()
  }

  /**
   * Determines whether service is available to use.
   */
  protected isServiceAvailable (): boolean {
    return typeof this.wishListService !== 'undefined'
  }

  /**
   * Creates new wishlist for guest/customer
   */
  private async createWishListOnMount (): Promise<void> {
    if (!this.wishListService) {
      return
    }

    try {
      const wishlistInstance = localStorage.getItem('wishlist')

      // If no wishlist has been found, create one.
      if (!wishlistInstance) {
        const newWishList = await this.wishListService.create()
        localStorage.setItem('wishlist', JSON.stringify(newWishList))
      }
    } catch (e) {
      if (e instanceof ResourceActionFailed) {
        logger(e, 'error')
      }
    }
  }

  private async fetchWishList (): Promise<void> {
    if (!this.wishListService) {
      return
    }

    try {
      const wishlist = await this.wishListService.fetch()

      this.$store.commit('wishlist/setWishList', wishlist)
    } catch (e) {
      logger(e, 'error')
    }
  }

  @Watch('isLoggedInUser')
  protected async onUser (value: boolean): Promise<void> {
    await this.fetchWishList()
  }
}
export default InitWishListMixin
