// Copyright © 2021 Move Closer

import { Locale, LocaleMessages } from 'vue-i18n'

import { frontAppPL, frontAppEn, frontAppUa, frontAppFr, frontAppCz, frontAppRu, frontAppDe, frontAppHu } from '@core'

import { FALLBACK_LOCALE, localeHtmlLangRegistry } from '@/bootstrap/i18n'
import { HtmlLang } from '@contract/i18n'

/**
 * Resolves the applicable `HtmlLang` for the passed-in `Locale`.
 *
 * @param locale - Current locale (language) of the app.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const getHtmlLangAttrValue = (locale: Locale): HtmlLang => {
  if (
    typeof locale !== 'string' ||
    !Object.prototype.hasOwnProperty.call(localeHtmlLangRegistry, locale) ||
    typeof localeHtmlLangRegistry[locale] !== 'string'
  ) {
    return getHtmlLangAttrValue(FALLBACK_LOCALE)
  }

  return localeHtmlLangRegistry[locale]
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author VueI18n
 */
export const loadLocaleMessages = (): LocaleMessages => {
  const localeMessages: LocaleMessages = {}

  localeMessages.pl = { ...localeMessages.pl, ...frontAppPL }
  localeMessages.en = { ...localeMessages.en, ...frontAppEn }
  localeMessages.ua = { ...localeMessages.ua, ...frontAppUa }
  localeMessages.fr = { ...localeMessages.fr, ...frontAppFr }
  localeMessages.cz = { ...localeMessages.cz, ...frontAppCz }
  localeMessages.ru = { ...localeMessages.ru, ...frontAppRu }
  localeMessages.de = { ...localeMessages.de, ...frontAppDe }
  localeMessages.hu = { ...localeMessages.hu, ...frontAppHu }

  return localeMessages
}

/**
 * Pushes the passed-in source messages into the given target object.
 *
 * @param target - The object that the messages should be pushed into.
 * @param source - The object that the messages should be extracted from.
 * @param [useMerge=false] - Determines whether the Lodash's `merge()` function should be used.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
// const pushMessages = (
//   target: LocaleMessages,
//   source: __WebpackModuleApi.RequireContext,
//   useMerge: boolean = false
// ): void => {
//   source.keys().forEach((filename: string) => {
//     const matches = filename.match(/([A-Za-z0-9-_]+)\./i)
//
//     if (matches && matches.length > 1) {
//       const locale: string = matches[1]
//       if (typeof target[locale] === 'undefined') {
//         target[locale] = {}
//       }
//       useMerge ? merge(target[locale], source(filename)) : target[locale] = source(filename)
//     }
//   })
// }
