import { AnyObject } from '@movecloser/front-core'

import { CreateWishlistInput, WishListItemInput, WishListModel } from '../../contract'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface IWishList {
  /**
   * Handles addition to wishlist.
   */
  add (id: string, item: WishListItemInput): Promise<WishListModel>

  /**
   * Creates new wishlist with passed in name and visibility
   */
  create(input?: CreateWishlistInput, context?: 'user' | 'guest'): Promise<AnyObject>

  /**
   * Fetches (loads) wishlists of the customer
   */
  fetch (): Promise<WishListModel>

  /**
   * Removes the item from the wishlist
   *
   * @param id - id of the wishlist
   * @param itemId - id of the specific item in list
   */
  remove (id: string, itemId: string): Promise<WishListModel>

  /**
   * Resolves the local wishlist.
   */
  resolveLocalWishlist (): void
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const WishListServiceType = Symbol.for('IWishList')
