/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface Przelewy24TransactionOutput {
  token: string
  transaction: string
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum CheckoutAgreement {
  EmailPromotion = 'email-promotion',
  SmsPromotion = 'sms-promotion'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CHECKOUT_VIEW_KEY = 'CheckoutView'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface CheckoutViewConfig {
  buttonIcon: string | null
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CHECKOUT_VIEW_CONFIG_MAP: CheckoutViewConfig = {
  buttonIcon: null
}
