




















import { Component, Mixins, Provide } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { DrawerWrapper, Inject, IS_MOBILE_PROVIDER_KEY, ISiteService, SiteServiceType } from '@core'
import { ModalWrapper } from '@/shared/core/src/front/shared/organisms/ModalWrapper'
import { CoreMixin } from '@/shared/core/src/front/shared/mixins'

import { ErrorDetails } from '@component/ErrorDetails.vue'
import { getHtmlLangAttrValue } from '@support/i18n'
import { isLocalEnv } from '@support/env'

import { HasTheme } from '@module/content/shared/HasTheme.mixin.vue'
import {
  ILocaleContentManager,
  LocaleContentManagerType
} from '@/shared/core/src/front/shared/services'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<App>({
  name: 'App',
  components: { DrawerWrapper, ErrorDetails, ModalWrapper },
  metaInfo (): MetaInfo {
    return {
      htmlAttrs: {
        lang: getHtmlLangAttrValue(this.$i18n.locale)
      },
      link: [
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `${process.env.BASE_URL}icons/favicon-32x32.png`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: `${process.env.BASE_URL}icons/favicon-16x16.png`
        },
        {
          rel: 'icon',
          href: `${process.env.BASE_URL}icons/favicon.ico`
        },
        {
          rel: 'manifest',
          href: `${process.env.BASE_URL}icons/site.webmanifest`
        },
        {
          rel: 'mask-icon',
          href: `${process.env.BASE_URL}icons/safari-pinned-tab.svg?v=1`,
          color: '#000000'
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: `${process.env.BASE_URL}icons/apple-touch-icon.png?v=1`
        },
        {
          rel: 'preconnect',
          href: process.env.VUE_APP_HTTP_API_BASEURL
        }
      ],
      titleTemplate: `%s | ${this.$t('_.siteName')}`,
      meta: [
        ...this.siteService.getActiveSiteMeta(),
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
        },
        {
          name: 'theme-color',
          content: '#ffffff'
        },
        {
          name: 'msapplication-TileColor',
          content: '#ffffff'
        },
        {
          name: 'msapplication-TileImage',
          content: `${process.env.BASE_URL}icons/mstile-144x144.png`
        },
        {
          name: 'apple-mobile-web-app-title',
          content: this.$t('_.siteName').toString()
        },
        {
          name: 'application-name',
          content: this.$t('_.siteName').toString()
        }
      ],
      script: [
        ...(process.env.VUE_APP_GTM_API_KEY ? [{
          once: true,
          vmid: 'gtm-container',
          async: false,
          defer: false,
          innerHTML: `
          window.dataLayer = window.dataLayer || [];
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),
          event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'
          ?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer',
          '${process.env.VUE_APP_GTM_API_KEY}');`,
          type: 'text/javascript'
        }] : [])
      ],
      __dangerouslyDisableSanitizersByTagID: {
        'gtm-container': ['innerHTML']
      }
    }
  }
})
export class App extends Mixins(CoreMixin, HasTheme) {
  @Inject(LocaleContentManagerType, false)
  protected readonly localeContentManager?: ILocaleContentManager

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  mounted () {
    // if (this.hasCookieBot) {
    //   this.enableCookieBotWidget()
    // }
  }

  // public get hasCookieBot (): boolean {
  //   if (!this.localeContentManager) {
  //     return true
  //   }
  //
  //   return !!this.localeContentManager.retrieve<boolean | undefined>('cookiebot')
  // }

  /**
   * Determines whether there are any errors to display.
   */
  public get hasErrors (): boolean {
    return !isLocalEnv() && this.$errorHandler.error !== null
  }

  /**
   * Determines whether the app is running on a mobile phone OR a tablet.
   */
  @Provide(IS_MOBILE_PROVIDER_KEY)
  public isMobile (): boolean {
    return this.$store.getters.isMobile
  }

  // public enableCookieBotWidget (): void {
  //   const cookiebot = document.createElement('script')
  //
  //   cookiebot.setAttribute('id', 'CookieDeclaration')
  //   cookiebot.setAttribute('src',
  //     'https://consent.cookiebot.com/25b67703-32ad-48e6-9f41-97a532333eb0/cd.js')
  //   cookiebot.setAttribute('type', 'text/javascript')
  //   cookiebot.async = true
  //   document.head.appendChild(cookiebot)
  //
  //   cookiebot.onload = () => {
  //     setTimeout(() => {
  //       const cookiebotWidget = document.getElementById('CookiebotWidget')
  //       if (cookiebotWidget) {
  //         cookiebotWidget.classList.add('CookiebotWidget__position')
  //       }
  //     }, 1000)
  //   }
  // }

  /**
   * When un-authenticated.
   */
  public onAuthRequired (): void {
    this.requireAuthentication()
  }
}

export default App
